import React from 'react'
import { Link } from 'react-router-dom'

// import logo from './images/logo-fff.png'
// import logo from './images/newlogo-fff.png'
// import './Navbar.css'
import logo1 from './images/logonew1.png'


export const Navbar = () => {
  return (
        <nav className='navbar'>
          <section className="navbar-section">
          <Link className="navbar-logo" to={'/'}>
            <img className='logo-new' src={logo1} alt='logo' />
          </Link>
          </section>
          <section className="navbar-section">
            <Link className='link-nav' to={'/Solar-Roof'}>Solar Roof</Link>
            <Link className='link-nav' to={'/Renovation'}>Renovation</Link>
            {/* <Link className='link-nav' to={'/Service'}>Services</Link> */}
          </section>
          <section className="navbar-section">
            <Link className='link-nav-btn' to={'/login'}>Sign In</Link>
          </section>
        </nav>
        
  )
}
export default Navbar
