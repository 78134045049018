import React, { useEffect, useState } from 'react'

// import './Slideshow.css'

function Slideshow({images, time}) {
    const [imageindex, setImageindex] = useState(0)

    useEffect(()=> {
        const timer = setInterval(()=>{
            setImageindex((prevImage) => (prevImage + 1)% images.length)
        },time)

        return ()=>{
            clearInterval(timer)
        }
    },[images.length, time])
    
    const selectSlide = (index) => {
        setImageindex(index)
    }

  return (
    <div>
        <section>
            <div className='slideshow'>
                {images.map((src, index) => (
                <img
                    key={index}
                    className={`renovation-img-tag ${
                    index === imageindex ? 'active' : ''
                    }`}
                    src={src}
                    alt={`Slide ${index + 1}`}
                />
                ))}
                <div className="dot-nav-slideshow">
                {images.map((src, index) => (
                    <span
                    key={index}
                    className={`dot ${index === imageindex ? 'active' : ''}`}
                    onClick={() => selectSlide(index)}
                    ></span>
                ))}
                </div>
            </div>
        </section>
    </div>
  )
}

export default Slideshow