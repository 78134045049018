import React, { useState } from 'react';
import { ReactComponent as ArrowIconRight } from './images/angles-right-solid.svg'
import { ReactComponent as ArrowIconLeft } from './images/angles-left.svg'

// import './Review.css'
import Typewriter from './Typewriter';

const Review = () => {
    const reviews = [
      
      {
          id: 1,
          name: 'Emily Johnson',
          review: 'We recently had Miracle Roofing and Remodeling replace our roof, and we are extremely satisfied with the results. The team was professional, efficient, and completed the job ahead of schedule. Our new roof looks fantastic, and we highly recommend their services!',
          stars: '⭐️⭐️⭐️⭐️⭐️'
      },
      {
          id: 2,
          name: 'David Smith',
          review: 'We hired Miracle Roofing and Remodeling to renovate our bathroom, and we couldn\'t be happier with the outcome. The team was courteous, communicative, and paid attention to every detail. Our bathroom looks stunning, and we will definitely use their services again in the future.',
          stars: '⭐️⭐️⭐️⭐️⭐️'
      },
      {
          id: 3,
          name: 'Sarah Miller',
          review: 'Miracle Roofing and Remodeling did an excellent job repairing our damaged siding. The crew was skilled, professional, and completed the work in a timely manner. We appreciate their attention to detail and highly recommend their services to anyone in need of home repairs.',
          stars: '⭐️⭐️⭐️⭐️'
      },
      {
          id: 4,
          name: 'Michael Davis',
          review: 'We had Miracle Roofing and Remodeling install new windows throughout our home, and we are thrilled with the results. The windows look fantastic, and we have noticed a significant improvement in energy efficiency. The team was punctual, knowledgeable, and a pleasure to work with.',
          stars: '⭐️⭐️⭐️⭐️⭐️'
      },
      {
          id: 5,
          name: 'Jennifer Brown',
          review: 'Miracle Roofing and Remodeling transformed our outdated kitchen into a modern and functional space. From the initial design consultation to the final installation, the team was professional, communicative, and exceeded our expectations. We are extremely pleased with the results!',
          stars: '⭐️⭐️⭐️⭐️⭐️'
      },
      {
          id: 6,
          name: 'Robert Wilson',
          review: 'We hired Miracle Roofing and Remodeling to remodel our basement, and we are delighted with the outcome. The team was attentive to our needs, provided creative solutions, and delivered high-quality workmanship. Our basement now feels like an extension of our home, and we highly recommend their services.',
          stars: '⭐️⭐️⭐️⭐️'
      },
      {
          id: 7,
          name: 'Jessica Taylor',
          review: 'Miracle Roofing and Remodeling recently completed a roof repair for us, and we are impressed with their professionalism and expertise. The crew was efficient, thorough, and left the job site clean. We are grateful for their prompt service and would not hesitate to recommend them to others.',
          stars: '⭐️⭐️⭐️⭐️⭐️'
      },
      {
          id: 8,
          name: 'Ryan Johnson',
          review: 'We had a positive experience working with Miracle Roofing and Remodeling to install new flooring in our home. The team was knowledgeable, respectful, and completed the project within our budget. While there were a few minor delays, overall, we are satisfied with the quality of their work.',
          stars: '⭐️⭐️⭐️⭐️'
      },
      {
          id: 9,
          name: 'Emily Clark',
          review: 'Miracle Roofing and Remodeling renovated our outdoor deck, and we are thrilled with the results. The team was professional, communicative, and completed the project on time. Our deck looks brand new, and we look forward to enjoying it for years to come. Highly recommended!',
          stars: '⭐️⭐️⭐️⭐️⭐️'
      },
      {
          id: 10,
          name: 'Daniel Williams',
          review: 'We recently hired Miracle Roofing and Remodeling to paint the exterior of our home, and we are pleased with the outcome. The team was thorough, detail-oriented, and completed the job with precision. While there were a few minor touch-ups needed, overall, we are satisfied with their workmanship.',
          stars: '⭐️⭐️⭐️⭐️'
      }
    ];
  
      
  const [currentReviewIndex, setCurrentReviewIndex] = useState(0);
  const [starsPrinted, setStarsPrinted] = useState(false);

  const handleNextReview = () => {
    setCurrentReviewIndex((prevIndex) => (prevIndex + 1) % reviews.length);
    setStarsPrinted(false);
  };

  const handleOldReview = () => {
    setCurrentReviewIndex((prevIndex) => (prevIndex - 1 + reviews.length) % reviews.length);
    setStarsPrinted(false);
  };

  const firstReview = reviews[currentReviewIndex];
  const secondReview = reviews[(currentReviewIndex + 1) % reviews.length];
  const thirdReview = reviews[(currentReviewIndex + 2) % reviews.length];

  const handleTypingEnd = () => {
    setStarsPrinted(true);
  };

  return (
    <div className="review-slideshow">
    <div className="review-box">
      <div className="review-item-1">
        <h2>{firstReview.name}</h2>
        <p>{firstReview.review}</p>
        <p>{firstReview.stars}</p>
      </div>
      {/* <div className={`review-item ${starsPrinted && secondReview.stars === '⭐️⭐️⭐️⭐️⭐️' ? 'gold-background' : ''}`}> */}
      <div className={`review-item ${starsPrinted && secondReview.stars === '⭐️⭐️⭐️⭐️⭐️' ? '' : ''}`}>
        <h2>{secondReview.name}</h2>
        <p>{secondReview.review}</p>
        <p><Typewriter message={secondReview.stars} time={250} loop={false}  newcard={currentReviewIndex} onTypingEnd={handleTypingEnd}/></p>
        <div className='button-right-review' onClick={handleNextReview}><ArrowIconRight/></div>
        <div className='button-left-review' onClick={handleOldReview}><ArrowIconLeft/></div>
      </div>
      <div className="review-item-1">
        <h2>{thirdReview.name}</h2>
        <p>{thirdReview.review}</p>
        <p>{thirdReview.stars}</p>
      </div>
    </div>
  </div>
  );
};

export default Review;