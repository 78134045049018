import React, { useState, useEffect, useRef } from 'react'
import house1 from '../../images/house.webp'
import discount from '../../images/discount1.svg'
import communication from '../../images/communication1.svg'
import security from '../../images/security1.svg'
import sustainability from '../../images/sustainability1.svg'


// import './Home.css'
import Review from '../../Review'
import { Link } from 'react-router-dom';

function Home() {

  const [selectedService, setSelectedService] = useState('Solar Roof')
  console.log('Width:', window.innerWidth, 'Height:', window.innerHeight);

  const handleServiceClick = (service) => {
    setSelectedService(service);
  };

  const [showSections, setShowSections] = useState({
    // section1: false,
    section2_1: false,
    section2_2: false,
    section2_3: false,
    section3_1: false,
    section3_2: false,
    section3_3: false,
    section4: false,
    section5: false,
  });

  const sectionRefs = {
    // section1: useRef(null),
    section2_1: useRef(null),
    section2_2: useRef(null),
    section2_3: useRef(null),
    section3_1: useRef(null),
    section3_2: useRef(null),
    section3_3: useRef(null),
    section4: useRef(null),
    section5: useRef(null),
  };

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.3,
    };

    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setShowSections((prevShowSections) => ({
            ...prevShowSections,
            [entry.target.id]: true,
          }));
        }
      });
    };

    const observer = new IntersectionObserver(callback, observerOptions);

    Object.values(sectionRefs).forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line 
  }, []);

  return (
    <div>

      {/* <section id='section1' className='home_section_1' ref={sectionRefs.section1} style={{ opacity: showSections.section1 ? 1 : 0, transform: showSections.section1 ? 'translateY(0)' : 'translateY(50px)',transition: 'opacity 0.5s ease-in-out, transform 1s ease-in-out',}}> */}
      <section id='section1' className='home_section_1'>
        <div className='intro_section'>
          <div className='intro_heading'>
            <h1 className='top_heading_home'>
                <span style={{ display: 'block' }}><span className='colorful'>Re</span>novate.</span>
                <span style={{ display: 'block' }}><span className='colorful'>Re</span>model.</span>
                <span style={{ display: 'block' }}><span className='colorful'>Re</span>imagine.</span>
                Your Dream Home Awaits!
            </h1>
          </div>

          <div className='image_home'>
            <img className='first_image_home' src={house1} alt='house'/>
          </div>
        </div>
        <div className='subheading'>At our renovation and remodeling company, we don't just build spaces; we craft experiences. From the initial spark of inspiration to the detailed design phase, the strategic planning, the hands-on construction, and the grand finale of project completion, our full-service approach guarantees a comprehensive, end-to-end solution for your renovation aspirations.</div>
        <Link to="/signup">
          <button className='startbutton'>Get Started</button>
        </Link>
      </section>
      
      <section className='home_section_2'>
        <div id='section2_1' className='section2_heading' ref={sectionRefs.section2_1} style={{ opacity: showSections.section2_1 ? 1 : 0, transform: showSections.section2_1 ? 'translateY(0)' : 'translateY(50px)',transition: 'opacity 0.5s ease-in-out, transform 1s ease-in-out',}}>Revolutionize your home upgrades with our empowering platform for a seamless and elevated improvement experience!</div>
        <div className='section2'>
          <div id='section2_2' className="left_column_section2" ref={sectionRefs.section2_2} style={{ opacity: showSections.section2_2 ? 1 : 0, transform: showSections.section2_2 ? 'translateX(0)' : 'translateX(-200px)',transition: 'opacity 0.5s ease-in-out, transform 1s ease-in-out',}}>
            <div className='subtitle_section2'>
              <img className="section2_svg" src={discount} alt="Icon" />
              <h2>Save up to 10% on your project expenses with enhanced transparency and control</h2>
              <p>Our clients have successfully reduced costs on materials, labor, and permits.¹ Gain real-time insights into project expenditures, identify potential savings opportunities, and enforce budget guidelines effectively.</p>
            </div>
            <div className='subtitle_section2'>
              <img className="section2_svg" src={sustainability} alt="Icon" />
              <h2>Achieve sustainability goals through data-driven decision-making</h2>
              <p>Track your project's environmental impact with our comprehensive carbon footprint calculator. Make informed choices that minimize waste, promote energy efficiency, and contribute to a greener building process.</p>
            </div>
          </div>
          <div id='section2_3' className="right_column_section2" ref={sectionRefs.section2_3} style={{ opacity: showSections.section2_3 ? 1 : 0, transform: showSections.section2_3 ? 'translateX(0)' : 'translateX(+200px)',transition: 'opacity 0.5s ease-in-out, transform 1s ease-in-out',}}>
            <div className='subtitle_section2'>
              <img className="section2_svg" src={communication} alt="Icon" />
              <h2>Empower your contractors with streamlined communication and collaboration</h2>
              <p>Provide contractors with secure access to project plans, specifications, and progress updates through a centralized platform. Facilitate seamless communication, foster teamwork, and ensure efficient project execution.</p>
            </div>
            <div className='subtitle_section2'>
              <img className="section2_svg" src={security} alt="Icon" />
              <h2>Prioritize safety and quality assurance for a seamless remodeling experience</h2>
              <p>Leverage our rigorous contractor screening and vetting process to ensure the highest standards of workmanship. Implement comprehensive safety protocols and quality control measures to safeguard your property and ensure a flawless renovation outcome.</p>
            </div>
          </div>
        </div>
      </section>

      <section className='home_section_3' >
          <div id='section3_1' className='reasons_heading' ref={sectionRefs.section3_1} style={{ opacity: showSections.section3_1 ? 1 : 0, transform: showSections.section3_1 ? 'translateY(0)' : 'translateY(50px)',transition: 'opacity 0.5s ease-in-out, transform 1s ease-in-out',}}>
            <h1>Choosing Excellence: The Triple Pillars that Define Us</h1>
          </div>

          <div id='section3_2' className='reason_para' ref={sectionRefs.section3_2} style={{ opacity: showSections.section3_2 ? 1 : 0, transform: showSections.section3_2 ? 'translateY(0)' : 'translateY(-100px)',transition: 'opacity 0.5s ease-in-out, transform 1s ease-in-out',}}>
            <p>Experience, commitment to customer satisfaction, and reputation for excellence are the three reasons why you should choose us for your next home improvement project.</p>
          </div>
          <div id='section3_3' className="reason_card_container" ref={sectionRefs.section3_3} style={{ opacity: showSections.section3_3 ? 1 : 0, transform: showSections.section3_3 ? 'translateY(0)' : 'translateY(50px)',transition: 'opacity 0.5s ease-in-out, transform 1s ease-in-out',}}>
            <div className="reason_cards">
              <h1>Artisanship</h1>
              <h3>Expertise in delivering high-quality construction and renovation projects.</h3>
              <p>Our skilled professionals ensure precision and attention to detail.</p>
            </div> 
            <div className="reason_cards">
              <h1>Customer Satisfaction</h1>
              <h3>Commitment to fulfilling client expectations and ensuring satisfaction.</h3>
              <p>Our skilled professionals ensure precision and attention to detail.</p>
            </div>
            <div className="reason_cards">
              <h1>Reliability & Trusts</h1>
              <h3>A reputation built on reliability, trust, and transparency in our dealings.</h3>
              <p>Clients trust us for our integrity and commitment to excellence.</p>
            </div>
          </div>
      </section>

      <section id='section4' className="home_section_4" ref={sectionRefs.section4} style={{ opacity: showSections.section4 ? 1 : 0, transform: showSections.section4 ? 'translateY(0)' : 'translateY(50px)',transition: 'opacity 0.5s ease-in-out, transform 1s ease-in-out',}}>
        <div className='showleft'>
          <div className='heading_list_services'>Embark on a Journey Through Our Service Offerings</div>
          <div className='services_buttons'>
              <div className={`service_link ${selectedService === 'Solar Roof' ? 'selected' : ''}`} onClick={() => handleServiceClick('Solar Roof')}>Solar Roof</div>
              <div className={`service_link ${selectedService === 'Re-modeling' ? 'selected' : ''}`} onClick={() => handleServiceClick('Re-modeling')}>Re-modeling</div>
              <div className={`service_link ${selectedService === 'Renovation' ? 'selected' : ''}`} onClick={() => handleServiceClick('Renovation')}>Renovation</div>
              <div className={`service_link ${selectedService === 'Interior' ? 'selected' : ''}`} onClick={() => handleServiceClick('Interior')}>Interior</div>
          </div>
          <Link to="/service">
            <button className='explore_button'>Explore Our Services</button>
          </Link>
        </div>
        
        <div className='showright'>
          <div className='service_information'>

          {selectedService === 'Solar Roof' && (
            <div className='service_details'>
              <h1>Solar Roof Installation</h1>
              <p>
                Transform your roof into a sustainable energy source with our Solar Roof Installation service. Our state-of-the-art solar panels not only provide clean and renewable energy but also enhance the aesthetic appeal of your home.
              </p>
              <ul>
                <li>Advanced solar panel technology</li>
                <li>Custom-designed to seamlessly integrate with your roof</li>
                <li>Energy-efficient and environmentally friendly</li>
                <li>Smart energy monitoring and management</li>
                <li>Professional installation by certified technicians</li>
              </ul>
            </div>
          )}

          {selectedService === 'Re-modeling' && (
            <div className='service_details'>
              <h1>Home Re-modeling</h1>
              <p>
                Experience a complete transformation of your home with our Home Re-modeling services. Our skilled team of designers and builders will work closely with you to bring your vision to life, creating a space that suits your lifestyle and preferences.
              </p>
              <ul>
                <li>Customized designs tailored to your unique style</li>
                <li>High-quality materials and craftsmanship</li>
                <li>Project management from concept to completion</li>
                <li>Timely and efficient construction process</li>
                <li>Attention to detail for a flawless finish</li>
              </ul>
            </div>
          )}

          {selectedService === 'Renovation' && (
            <div className='service_details'>
              <h1>Property Renovation</h1>
              <p>
                Revitalize your property with our comprehensive Property Renovation services. Whether it's updating the exterior for curb appeal or modernizing the interior for comfort, we ensure that your property reflects your taste and meets current design standards.
              </p>
              <ul>
                <li>Exterior facelifts, landscaping, and hardscaping</li>
                <li>Interior remodeling and space optimization</li>
                <li>Energy-efficient upgrades for cost savings</li>
                <li>Quality materials and sustainable practices</li>
                <li>Professional consultation and project management</li>
              </ul>
            </div>
          )}

          {selectedService === 'Interior' && (
            <div className='service_details'>
              <h1>Interior Design</h1>
              <p>
                Elevate your living spaces with our expert Interior Design services. Our team of designers focuses on creating functional, stylish, and personalized interiors that align with your lifestyle and preferences.
              </p>
              <ul>
                <li>Personalized design consultations and concept development</li>
                <li>Selection of quality furnishings, lighting, and decor</li>
                <li>Space planning for optimal functionality and flow</li>
                <li>Color schemes and material selections for a cohesive look</li>
                <li>Collaboration with architects and contractors for seamless execution</li>
              </ul>
            </div>
          )}

          </div>
        </div>
      </section>

      <section id='section5' className='home_section_5' ref={sectionRefs.section5} style={{ opacity: showSections.section5 ? 1 : 0, transform: showSections.section5 ? 'translateY(0)' : 'translateY(50px)',transition: 'opacity 0.5s ease-in-out, transform 1s ease-in-out',}}>
        <div className='Review_heading_home'>Don't just take our word for it. Hear what our clients have to say about our work and professionalism.</div>
        <div className='slide_review'>
          <Review />
        </div>
      </section>

    </div>
   )
 }

 export default Home