import React from 'react'
import Desksetup from '../../images/desksetup.png'
import Kitchen from '../../images/kitchen.png'
import Kitchen1 from '../../images/kitchen1.png'
import Kitchen2 from '../../images/kitchen2.png'
import dining from '../../images/dinning.png'
import dining1 from '../../images/dining1.png'

import hall from '../../images/hall.png'
import hall1 from '../../images/hall1.png'
import hall2 from '../../images/hall2.png'
import hall3 from '../../images/hall3.png'
import hall4 from '../../images/hall4.png'

import office from '../../images/office.png'
import office1 from '../../images/office1.png'
import office2 from '../../images/office2.png'

import bedroom from '../../images/bedroom.png'
import bedroom2 from '../../images/bedroom2.png'
import bedroom3 from '../../images/bedroom3.png'
import bedroom4 from '../../images/bedroom4.png'
import bedroom5 from '../../images/bedroom5.png'
import bedroom6 from '../../images/bedroom6.png'



// import './Renovation.css'
import Slideshow from '../../Slideshow'



function Renovation() {

  return (
    <div className='renovation-page'>
        <section>
            <div className='image-renovation-full'>
                <img className='renovation-image' src={Desksetup} alt='desk setup'/>
            </div>
        </section>

        <section>
            <div className='text-renovation-full'>
                <p> <span className='light-text'>we specialize in transforming your living spaces into your dream home. Whether you're looking to renovate your </span>
                <span className="bold-renovation">
                    <a className='goto_section_renovation' href='#Kitchen'>Kitchen</a>, <a className='goto_section_renovation' href='#Hall'>Hall</a>, <a className='goto_section_renovation' href='#Bedroom'>Bedrooms</a> or <a className='goto_section_renovation' href='#Office'>Office</a></span><span className='light-text'> , our team of skilled professionals is here to bring your vision to life. With years of experience and a commitment to excellence, we pride ourselves on delivering exceptional results that exceed your expectations.</span></p>
            </div>
        </section>

        <section id='Kitchen'>
            <div className='Renovation-page-section'>
                <div className='text-renovation'>
                    <h2>Kitchen Renovations</h2>
                    <p>Your kitchen is the heart of your home, and we understand the importance of creating a space that is not only functional but also visually stunning. From modern designs to classic styles, our team will work closely with you to create a kitchen that reflects your personal taste and lifestyle. We handle everything from cabinet installations and countertop replacements to flooring and lighting upgrades.</p>
                </div>
            </div>
        </section>

        <section>
                <Slideshow images={[Kitchen, Kitchen1, Kitchen2, dining, dining1]} time={5000} />
        </section>

        <section id='Hall'>
            <div className='Renovation-page-section'>
                <div className='text-renovation'>
                    <h2>Hall Renovations</h2>
                    <p>The hall is often the first impression your guests have of your home. Whether you're looking for a complete transformation or a simple update, our skilled team will help you create a welcoming and stylish space. We specialize in hall flooring, painting, lighting, and furniture arrangements to maximize the functionality and aesthetic appeal of your hall.</p>
                </div>
            </div>
        </section>

        <section>
                <Slideshow images={[hall, hall1, hall2, hall3, hall4]} time={5000} />
        </section>

        <section id='Bedroom'>
            <div className='Renovation-page-section'>
                <div className='text-renovation'>
                    <h2>Bedroom Renovations</h2>
                    <p>Your bedroom should be a sanctuary, a place where you can relax and unwind. Our team will work closely with you to create a personalized bedroom that suits your style and preferences. From creating custom storage solutions to selecting the perfect color scheme, we pay attention to every detail to ensure your bedroom becomes a tranquil retreat.</p>
                </div>
            </div>
        </section>

        <section>
                <Slideshow images={[bedroom, bedroom2, bedroom3, bedroom4, bedroom5, bedroom6]} time={5000} />
        </section>

        <section id='Office'>
            <div className='Renovation-page-section'>
                <div className='text-renovation'>
                    <h2>Office Renovations</h2>
                    <p>A well-designed office space can greatly enhance productivity and creativity. Whether you work from home or have a commercial office, we can help you create a functional and inspiring workspace. Our services include office layout design, custom cabinetry, ergonomic furniture selection, and technology integration.</p>
                </div>
            </div>
        </section>

        <section>
                <Slideshow images={[office, office1, office2, Desksetup]} time={5000} />
        </section>

    </div>
  )
}

export default Renovation