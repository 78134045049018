import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import logo from '../../images/logo-fff.png'
import { ReactComponent as Addresscard } from '../../../component/images/address-card.svg'
import { ReactComponent as Profile } from '../../../component/images/user.svg'
import { ReactComponent as Creditcard } from '../../../component/images/credit-card-solid.svg'
import { ReactComponent as Password } from '../../../component/images/key-solid.svg'
import { ReactComponent as Delete } from '../../../component/images/trash.svg'
import { ReactComponent as Edit } from '../../../component/images/pencil-solid.svg'
import { ReactComponent as Lock } from '../../../component/images/lock-solid.svg'
import { ReactComponent as Unlock } from '../../../component/images/unlock-solid.svg'
import { ReactComponent as Eye } from '../../../component/images/eye-solid1.svg'
import { ReactComponent as CloseEye } from '../../../component/images/eye-slash-solid1.svg'
import { ReactComponent as Update } from '../../../component/images/pen-to-square-regular.svg'
import { ReactComponent as LockEye } from '../../../component/images/visibility_lock.svg'
import { ReactComponent as Verified } from '../../../component/images/verified_user.svg'
import { ReactComponent as Invoiceicon } from '../../../component/images/invoice.svg'
import { ReactComponent as Paidicon } from '../../../component/images/Paid.svg'
import { ReactComponent as Overdueicon } from '../../../component/images/Overdue.svg'
import { ReactComponent as Pendingicon } from '../../../component/images/Pending.svg'
import { ReactComponent as Totalicon } from '../../../component/images/Total.svg'
import Nav from '../Nav/Nav';



function Account() {

  const [currentpassword, setCurrentpassword] = useState('')
  const [newpassword, setNewpassword] = useState('')
  const [confirmnewpassword, setConfirmnewpassword] = useState('')
  const [showChange, setShowChange] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [accountLock, setAccountLock] = useState(false)
  const [fetcheddata, setFetcheddata] = useState(window.localStorage.getItem('data'))
  const [isfetching, setIsfetching] = useState(true)
  const [showEdit, setShowEdit] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [dob, setDob] = useState('')
  const [address, setAddress] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')

  const [cardNumber, setCardNumber] = useState('');
  const [cardName, setCardName] = useState('');
  const [cardexpiry, setCardexpiry] = useState('');
  const [cvv, setCvv] = useState('');
  const [selectedCard, setSelectedCard] = useState(null);
  const [showCVVPopup, setShowCVVPopup] = useState(false);
  const [cvvInput, setCVVInput] = useState('');
  const [isCVVConfirmed, setIsCVVConfirmed] = useState(false);
  const [cards, setCards] = useState([]);

  const [addresses, setAddresses] = useState([]);
  const [selectedType, setSelectedType] = useState('');
  const [name, setName] = useState('');
  const [address1, setAddress1] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [addaddresscard, setAddaddresscard] = useState(false);
  const [editedAddress, setEditedAddress] = useState(null);
  const [clickedFields, setClickedFields] = useState({ name: false, address1: false, city: false, selectedType: false, state: false, postalCode: false});
  const [newAccountStatus, setNewAccountStatus] = useState(window.localStorage.getItem('Accountstatus'));
  const [newAddress_exist, setNewAddress_exist] = useState(window.localStorage.getItem('Address_exist'))

  const [invoices, setInvoices] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const token = window.localStorage.getItem("token")
  const headers =  {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization:token,
    "Access-Control-Allow-Origin": "*",
  }
  
  useEffect(() => {
    const fetchaccountdata = async () => {
      try {
        const response = await fetch("http://localhost:3500/accountData", {
          method: "POST",
          crossDomain: true,
          headers:headers,
          body: JSON.stringify({
            token: window.localStorage.getItem("token"),
          }),
        });

        const data = await response.json();
        if (data.status === 'ok') {
          setFetcheddata(data.data)
          setIsfetching(false)
          // console.log(data.data, "account data is fetched"); 
        }
      } catch (error) {
        console.log('Error fetching user data:', error);
      }
    };

    if (isfetching) {
      fetchaccountdata();
    }
    // eslint-disable-next-line
  }, [isfetching]);

  useEffect(() => {
    if (newAccountStatus === "true" && fetcheddata) {
      setShowEdit(true);
      setFirstName(fetcheddata.firstName);
      setLastName(fetcheddata.lastName);
      if (fetcheddata.dob) {
        setDob(fetcheddata.dob.split('T')[0]);
      }
      setAddress(fetcheddata.address);
      setEmail(fetcheddata.email);
      setPhone(fetcheddata.phone);
    }
  }, [fetcheddata, newAccountStatus]);


  const [selectedOption, setSelectedOption] = useState(localStorage.getItem('selectedOption') || 'Profile');

  useEffect(() => {
    localStorage.setItem('selectedOption', selectedOption);
  }, [selectedOption]);


  // Function to handle option selection and set the user data accordingly
  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setShowEdit(false);
    setShowChange(false);
    setAddaddresscard(false);
    setAddcard(false);
  };

  const [passwordstrength, setPasswordstrength] = useState('')

  const getPasswordStrength = (password, confirmPassword) => {
    if (password !== confirmPassword) {
      if (passwordstrength !== '') {
        // Set password strength only if it's not already set
        setPasswordstrength('');
      }
      return 'Passwords do not match';
    }
  
    if (password.length < 8) {
      if (passwordstrength !== 'weak') {
        setPasswordstrength('weak');
      }
      return 'Weak: Password should be at least 8 characters long';
    }
  
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasDigit = /\d/.test(password);
    const hasSpecialChar = /[\W_]/.test(password);
    const hasSequentialChars = /(123|abc|qwerty)/i.test(password);
    const hasRepeatedChars = /(.)\1{2,}/.test(password);

    if ((hasSequentialChars && hasRepeatedChars) || hasRepeatedChars || hasSequentialChars) {
      if (passwordstrength !== 'weak') {
        setPasswordstrength('weak');
      }
      return 'Very Weak: Avoid sequential characters, repeated characters';
    }
  
    if (hasUpperCase && hasLowerCase && hasDigit && hasSpecialChar) {
      if (passwordstrength !== 'strong') {
        setPasswordstrength('strong');
      }
      return 'Strong: Password meets multiple criteria';
    }
  
    if (hasUpperCase || hasLowerCase || hasDigit || hasSpecialChar) {
      if (passwordstrength !== 'medium') {
        setPasswordstrength('medium');
      }
      return 'Medium: Password meets some criteria';
    }
  
    if (passwordstrength !== 'weak') {
      setPasswordstrength('weak');
    }
    return 'Weak: Password should include uppercase, lowercase, digit, and special character';
  };
  
  


  const generatePassword = () => {
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+';
    let password = '';
    for (let i = 0; i < 15; i++) {
      password += letters.charAt(Math.floor(Math.random() * letters.length));
    }
    setNewpassword(password);
    setConfirmnewpassword(password);
  };

  useEffect(() => {
    if (!showChange) {
      setCurrentpassword('');
      setNewpassword('');
      setConfirmnewpassword('');
    }
  }, [showChange]);

  const handlechangePassword = async () => {
    if (!currentpassword || !newpassword || !confirmnewpassword) {
      alert('Fill all the fields. Do not leave empty fields');
      return;
    }
  
    if (newpassword !== confirmnewpassword) {
      alert('New password and confirmation do not match');
      return;
    }

    if (passwordstrength === 'weak') {
      alert('Password Strength is weak !!!');
      return;
    }
  
    try {
      const currentdate= new Date()
      const IncreaseDays = () => {
        const newDate = new Date();
        newDate.setDate(newDate.getDate() + 250);
        return newDate;
      };
      const ExpiryDate = IncreaseDays()
      const updatePasswordData = {
        oldPassword: currentpassword,
        newPassword: newpassword,
        lastpasswordchange: currentdate.toLocaleString(),
        ExpiryDate : ExpiryDate.toLocaleString()
      };
  
      console.log(updatePasswordData);
  
      const response = await fetch(`http://localhost:3500/updatePassword/${fetcheddata.accountid}`, {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify(updatePasswordData),
      });
  
      const data = await response.json();
      console.log('Password update response:', data);
  
      if (data.status === 'ok') {
        alert('Password updated successfully');
      } else {
        alert('Password update failed');
      }
    } catch (error) {
      console.error('Error updating password:', error);
      alert('An error occurred while updating the password');
    }
  };
  

  const navigate = useNavigate();

  const handlelock = async () => {
    if (window.confirm("Are you sure you want to lock the account")) {
      setAccountLock(true)
      const lockedDetails = {
        accountlock: true
      };
      try {
        const response = await fetch(`http://localhost:3500/accountlocked/${fetcheddata._id}`, {
          method: 'PUT',
          headers: headers,
          body: JSON.stringify(lockedDetails)
        });

        if (!response.ok) {
          throw new Error(`Failed to lock account. Status: ${response.status}`);
        }

        const data = await response.json();
        console.log('Account Locked Successfully:', data);

        window.localStorage.clear();
        console.log("locked");
        navigate('/login');
      } catch (error) {
        console.error('Error Updating Account:', error);
      }
    }
  }


  const handleunlock = () => {
    setAccountLock(false)
  }
  const handleeditprofile = () =>{
    
    setShowEdit(true)

    setFirstName(fetcheddata.firstName);
    setLastName(fetcheddata.lastName);
    if (fetcheddata.dob) {
      setDob(fetcheddata.dob.split('T')[0]);
    }
    setAddress(fetcheddata.address);
    setEmail(fetcheddata.email);
    setPhone(fetcheddata.phone);
  }

  const handleCancelEdit = () => {
    setShowEdit(false)
    
  }

  const handleEditacc = async () => {
    if (firstName !== '' && lastName !== '' && dob !== '' && address !== '' && email !== '' && phone !== '') {
      const newdata = {
        firstName: firstName,
        lastName: lastName,
        dob: dob,
        address: address,
        email: email,
        phone: phone
      };
  
      try {
        const response = await fetch(`http://localhost:3500/UserAccData/${fetcheddata._id}`, {
          method: 'PUT',
          headers: headers,
          body: JSON.stringify(newdata)
        });
  
        const data = await response.json();
        if (response.ok) {
          setNewAccountStatus(false);
          localStorage.setItem("Accountstatus",false);
          console.log('Account updated successfully:', data);
          setShowEdit(false);
          setIsfetching(true);
        }

      } catch (error) {
        console.error('Error updating account:', error);
      }
    } else {
      alert('Fill all the fields. Do not leave empty fields');
    }
  };

  // CREDIT CARD
  const [addcard, setAddcard] = useState(false)

  const fetchAndUpdateCards = async () => {
    try {
      const response = await fetch(`http://localhost:3500/api/creditcards/${fetcheddata.accountid}`, {
        headers: headers,
      });
  
      if (response.ok) {
        const data = await response.json();
        // console.log("Fetched card data: ", data);
        const mappedCards = data.map(card => ({
          cardNumber: card.cardNumber,
          cardexpiry: card.cardexpiry,
          cvv: card.cvv,
          cardName: card.cardName,
          cardlogo: card.cardlogo,
        }));
        setCards(mappedCards);
      } else {
        console.error('Error fetching card data:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching card data:', error);
    }
  };
  

  useEffect(() => {
    if (fetcheddata && fetcheddata.accountid) {
      // async function fetchAndUpdateCards() {
      //   try {
      //     const response = await fetch(`http://localhost:3500/api/creditcards/${fetcheddata.accountid}`,{
      //       headers:headers,
      //     });

      //     if (response.ok) {
      //       const data = await response.json();
      //       console.log("Fetched card data: ", data);
      //       const mappedCards = data.map(card => ({
      //         cardNumber: card.cardNumber,
      //         cardexpiry: card.cardexpiry,
      //         cvv: card.cvv,
      //         cardName: card.cardName,
      //         cardlogo: card.cardlogo,
      //       }));
      //       setCards(mappedCards);
      //     } else {
      //       console.error('Error fetching card data:', response.statusText);
      //     }
      //   } catch (error) {
      //     console.error('Error fetching card data:', error);
      //   }
      // }
      fetchAndUpdateCards();
    }
    // eslint-disable-next-line
  }, [fetcheddata]);

  const handlesavecard = async (e) => {
    e.preventDefault()
    let cardCompany;
    if (cardNumber.toString().startsWith('37') || cardNumber.toString().startsWith('34')  ) {
      cardCompany = 'American Express'
    } else if (cardNumber.toString().startsWith('4')) {
      cardCompany = 'Visa Card'
    } else if (cardNumber.toString().startsWith('5')) {
      cardCompany = 'Master Card'
    } else if (cardNumber.toString().startsWith('6')) {
      cardCompany = 'Discover'
    } else {
      alert('invalid card number')
      return;
    }

    if (!cardexpiry.match(/^(0[1-9]|1[0-2])\/\d{2}$/)) {
      alert('Not valid. Please use MM/YY format with the month between 01 and 12.')
      return;
    }

    const todaydate = new Date();

    const currentYear = todaydate.getFullYear();
    const expiryYear = parseInt(cardexpiry.substring(3), 10) + currentYear - (currentYear % 100);
    const expiryMonth = parseInt(cardexpiry.substring(0, 2), 10);

    if (expiryYear < currentYear || (expiryYear === currentYear && expiryMonth < todaydate.getMonth() + 1)) {
      alert('Card has expired.');
      return;
    }
    
    const newcard = {
      cardName:cardName,
      cardNumber:cardNumber,
      cardexpiry:cardexpiry,
      cvv:cvv,
      cardlogo:cardCompany,
      accountid: fetcheddata.accountid
    }

    console.log(newcard)
    
    try {
      const response = await fetch(`http://localhost:3500/api/creditcards`, {
        method: 'POST',
        headers:headers,
        body: JSON.stringify(newcard),
      });

      if (response.ok) {
        const savedCard = await response.json();
        console.log('Credit card data saved:', savedCard);
        fetchAndUpdateCards();
        handlecancelcard();
      } else {
        console.error('Error saving credit card data:', response.statusText);
      }
    } catch (error) {
      console.error('Error saving credit card data:', error);
    }
  }

  const handlecancelcard = () => {
    setCardName('')
    setCardNumber('')
    setCardexpiry('')
    setCvv('')
    setAddcard(false)
    
  }

  const handleCardClick = (index) => {
    if (selectedCard === index) {
      if(isCVVConfirmed){
      setSelectedCard(null); // Reset the selected card if clicked again
      setShowCVVPopup(false); // Hide the CVV popup if the same card is clicked again
      setIsCVVConfirmed(false)
      setCVVInput(""); // Clear the CVV input when the card is deselected
      }
    } else {
      setSelectedCard(index); // Otherwise, set the selected card to the clicked index
      setIsCVVConfirmed(false)
      setShowCVVPopup(true); // Show the CVV popup when a card is clicked
      setCVVInput(""); // Clear the CVV input for the new selected card
    }
  };
  const handleCVVSubmit = () => {
    const selectedCardData = cards[selectedCard];
    if (selectedCardData.cvv === cvvInput) {
      setShowCVVPopup(false);
      setIsCVVConfirmed(true)
    } else {
      alert("Invalid CVV. Please try again.");
    }
  };

  const handleCVVcancel = () => {
    setShowCVVPopup(false);
    setSelectedCard(null);
  }

  const handledeletecard = async (indexToDelete) => {
    //code here to delete the card
    const cardToDelete = cards[indexToDelete];     
    if (window.confirm("Are you sure you want to delete this card?")) {
      try {
        const response = await fetch(`http://localhost:3500/api/creditcards/${cardToDelete.cardNumber}`, {
          method: 'DELETE',
          headers:headers,
        });

        if (response.ok) {
          console.log('Credit card data deleted successfully.');
          // Update the 'cards' state by removing the deleted card
          setCards(prevCards => prevCards.filter((_, index) => index !== indexToDelete));
        } else {
          console.error('Error deleting credit card data:', response.statusText);
        }
      } catch (error) {
        console.error('Error deleting credit card data:', error);
      }
    }
  }

  const formatCardNumber = (input) => {
    const digitsOnly = input.replace(/\D/g, "");
    // Split the digits into groups of four and join them with spaces
    const formattedNumber = digitsOnly
      .replace(/(\d{4})(?=\d)/g, "$1 ") // Add a space after every four digits
      .trim();
    return formattedNumber;
  };
  const formatexpirydate = (input) => {
    const digitsOnly = input.replace(/\D/g, "");

    const expirydatenumber = digitsOnly
      .replace(/(\d{2})(?=\d)/g, "$1/")
    return expirydatenumber;
  }
  const handleCardNumberChange = (e) => {
    const inputCardNumber = e.target.value;
    const formattedCardNumber = formatCardNumber(inputCardNumber);
    setCardNumber(formattedCardNumber);
  };

  const handleexpirydate = (e) => {
    const inputexpiryNumber = e.target.value;
    const expirydatenumber = formatexpirydate(inputexpiryNumber);
    setCardexpiry(expirydatenumber);
  }

  // Address Page

// displays Address 

  const fetchaddress = async () => {
    try {
      const addResponse = await fetch("http://localhost:3500/fetchAdd", {
        method: "POST",
        crossDomain: true,
        headers:headers,
        body: JSON.stringify({
          token: window.localStorage.getItem("token"),
        }),
      });

      const data = await addResponse.json();
      if (data.status === 'ok') {
        setAddresses(data.data);
      }
    } catch (error) {
      console.log('Error fetching Address data:', error);
    }
  };

  useEffect(() => {
    if (newAddress_exist === "false") {
      setSelectedOption('Address Card');
    } else if (newAddress_exist === "true") {
      fetchaddress();
    }
    // eslint-disable-next-line
  }, [newAddress_exist]);
 

  const handleBlur = (field) => {
    setClickedFields((oldfields) => ({
      ...oldfields,
      [field]: true,
    }));
  };

  const isFieldEmpty = (field) => {
    return clickedFields[field] && !getFieldValue(field);
  };

  const getFieldValue = (field) => {
    return {
      name,
      address1,
      city,
      selectedType,
      state,
      postalCode,
    }[field];
  };

  const validStates = [
    'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado',
    'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho',
    'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine',
    'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi',
    'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey',
    'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma',
    'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota',
    'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington',
    'West Virginia', 'Wisconsin', 'Wyoming'
  ];
  const [suggestions, setSuggestions] = useState([]);

  const handleInpuChange = (e) => {
    const inputValue = e.target.value;
    const matchingStates = validStates.filter(state =>
      state.toLowerCase().includes(inputValue.toLowerCase())
    );
    setSuggestions(matchingStates);
    setState(inputValue);
  };

  const handleSuggestionClick = (suggestion) => {
    setState(suggestion);
    setSuggestions([]);
  };

  // Save Address card

  const SaveAddressCard = async (event) => {
    event.preventDefault();

    if (!name || !address1 || !city || !state || !postalCode || !fetcheddata.accountid) {
      console.error('Please fill in all required fields.');
      return; 
    }

    const newAddress = {
      type: selectedType,
      name: name,
      address: address1,
      city: city,
      state: state,
      postalCode: postalCode,
      accountid: fetcheddata.accountid
    };
  
    try {
      const response = await fetch("http://localhost:3500/userAddress", {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(newAddress)
      });
  
      if (response.ok) {
        const data = await response.json();
        console.log(data.message);

        if (data.message === "Addresscreatedsuccessfully") {
          setNewAddress_exist(true);
          window.localStorage.setItem('Address_exist', 'true');
          fetchaddress();
          setAddaddresscard(false)
          canceladdresscard()
          setClickedFields({ name: false, address1: false, city: false, selectedType: false, state: false, postalCode: false});

        }
      } 
    } catch (error) {
      console.error('Error updating account:', error);
    }
  };

  const canceladdresscard = () => {
    setName('');
    setAddress1('');
    setCity('');
    setSelectedType('');
    setState('');
    setPostalCode('');
  };
  

  // Delete address

  const deleteAddress = async (addressId) => {
    if (window.confirm("Are you sure you want to delete the address card?")) {
      try {
        const response = await fetch(`http://localhost:3500/addresses/${addressId}`, {
          method: 'DELETE',
          headers:headers,
        });
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Failed to delete address');
        }
        if (response.ok) {
          const data = await response.json();
          if (data.addresscount > 0) {
            fetchaddress();
          } else {
            setNewAddress_exist(false)
            window.localStorage.setItem('Address_exist', 'false');
          }
        }
        
      } catch (error) {
        console.error('Error deleting address:', error.message);
      }
    }
  };

  const handleEditAddress = (address) => {
    setEditedAddress(address);
  };

  const handleUpdateAddress = () => {
    updateAddress(editedAddress._id, editedAddress);
    setEditedAddress(null);
  };

  const handleCancelEdit1 = () => {
    setEditedAddress(null);
  };
  
  
  // Used to edit address 
  
  const updateAddress = async (addressId, updatedData) => {
    try {
      const response = await fetch(`http://localhost:3500/addresses/${addressId}`, {
        method: 'PUT', 
        headers:headers,
        body: JSON.stringify(updatedData),
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to update address');
      }
      fetchaddress()
      console.log('Address updated successfully');
    } catch (error) {
      console.error('Error updating address:', error.message);
    }
  };
  
  

  // Account Delete


  const handleAccountDelete = async () => {
    if (window.confirm("Are you sure you want to delete the Account? You will no longer be able to access the services")) {
      try {
          // setAccountDelete(true)
          const DeletedAccount = {
              accountDelete: true
          };
  
          const response = await fetch(`http://localhost:3500/accountdeleted/${fetcheddata._id}`, {
              method: 'PUT',
              headers: headers,
              body: JSON.stringify(DeletedAccount)
          });
  
          if (!response.ok) {
              throw new Error('Failed to delete account');
          }
  
          const data = await response.json();
          console.log('Account Deletion Successfully Requested:', data);
  
          window.localStorage.clear();
          console.log("Request Submitted");
          navigate('/login');
  
      } catch (error) {
          console.error('Error Deleting Account:', error);
      }
    };
  }

  const handlenewcard = () => {
    if (cards.length === 3) {
      console.log('Already three cards exists')
      setAddcard(false)
    } else {
      setAddcard(true)
    }
  }

  const [verification, setVerification] = useState(localStorage.getItem('verification') || false);
  const [securitycode, setSecuritycode] = useState(false);
  const [message, Setmessage] = useState('');
  const [verificationCodes, setVerificationCodes] = useState(['', '', '', '', '', '']);
  const inputRefs = useRef([...Array(6)].map(() => React.createRef()));

  useEffect(() => {
    localStorage.setItem('verification', verification);
  }, [verification]);

  const handleverification =  async () => {
    console.log('verification is clicked')
    const id = window.localStorage.getItem("Id")

    console.log(id)

    if (verification === true) {
      console.log('Verification is already true. No need to send the request.');
      return;
    }

    try {
      const response = await fetch(`http://localhost:3500/verification${id}`, {
        method: 'POST', 
        headers:headers,
        body: JSON.stringify({
          token: window.localStorage.getItem("token"),
        }),
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to Verify Account');
      }

      if (response.ok) {
        setVerification(true)
        console.log('otp sent successfully');
    }

    } catch (error) {
      console.error('Error sending otp:', error.message);
    }
  }

  const handleInputChange = (index, e) => {
    const value = e.target.value;

    if (/^\d*$/.test(value) && value.length <= 1) {
      const newVerificationCodes = [...verificationCodes];
      newVerificationCodes[index] = value;
      setVerificationCodes(newVerificationCodes);

      if (newVerificationCodes.every((code) => code.length === 1)) {
        const codeString = newVerificationCodes.join('');
        submitverification(codeString);
        
      } else if (index < 5 && value.length === 1) {
        // Move focus to the next input field
        inputRefs.current[index + 1].current.focus();
      }
    }
  };

  const submitverification = async (verify_code) => {
    const id = window.localStorage.getItem("Id")
    const code = {
      accountid: id,
      verificationcode: verify_code
    };
    try {
      const response = await fetch(`http://localhost:3500/verify_otp`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          token: window.localStorage.getItem("token"),
          code
        }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to Verify Account');
      }
      if (response.ok) {
        setVerification(false);
        console.log('Verification completed successfully');
      }
    } catch (error) {
      console.error('Error verifying account:', error.message);
      Setmessage(error.message)
    }
  };


  const seepasscode = () => {
    setSecuritycode(true)
  }

  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    setCursorPosition({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY });
  };

  const confirmpasswordbutton = newpassword.trim() === '' || confirmnewpassword.trim() === '' || currentpassword.trim() === '';
  const passwordequal = newpassword.trim() !== '' && newpassword.trim() === confirmnewpassword.trim();

  const addressnewsubmit = name.trim() === '' || address1.trim() === '' || city.trim() === '' || state.trim() === '' || postalCode.trim() === '';


  const fetchInvoices = async () => {
    try {
      const response = await fetch(`http://localhost:3500/getinvoiceuser/${fetcheddata.email}`, {
        method: "GET", 
        headers: headers,
      });
  
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        setInvoices(data);
      } else if (response.status === 404) {
        console.error('Invoices not found for this customer');
      } else {
        console.error('Failed to fetch invoices:', response.statusText);
      }
    } catch (error) {
      console.error('An error occurred while fetching invoices:', error);
    }
  };
  const viewInvoice = (invoice) => {
    setSelectedInvoice(invoice);
    
  };
  const closeInvoice = () => {
    setSelectedInvoice(null);
  };

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Filter invoices based on the search query
  const filteredInvoices = invoices.filter((invoice) => {
    return (
      invoice.invoiceNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
      invoice.customerName.toLowerCase().includes(searchQuery.toLowerCase())
      // Add more fields as needed for searching
    );
  });
  
  return (
    <div>
       <section className='profile-user'>
            <div>
              <Nav />
            </div>
        </section>
        <div className='page-content'>


        <div className='sidebar'>
          <div className='nav-account'>
            <div className={`${'Profile' === selectedOption ? 'top_account' : ''}`}>
                <div className={`nav-account-btn ${'Profile' === selectedOption ? 'active-btn-top' : ''}`} onClick={() => handleOptionClick('Profile')}><Profile width={"18px"} height={"18px"} /> Profile</div>
              <div className='nav-select-bottom'></div>
            </div>
            <div className={`${'Password' === selectedOption ? 'account_middle' : ''}`}>
              <div className='nav-select-top'></div>
              <div className={`nav-account-btn ${'Password' === selectedOption ? 'active-btn' : ''}`} onClick={() => handleOptionClick('Password')}><Password width={"18px"} height={"18px"} /> Password</div>
              <div className='nav-select-bottom'></div>
            </div>
            <div className={`${'Address Card' === selectedOption ? 'account_middle' : ''}`}>
              <div className='nav-select-top'></div>
              <div className={`nav-account-btn ${'Address Card' === selectedOption ? 'active-btn' : ''}`} onClick={() => handleOptionClick('Address Card')}><Addresscard width={"18px"} height={"18px"} />Address</div>
              <div className='nav-select-bottom'></div>
            </div>
            <div className={`${'Payment' === selectedOption ? 'account_middle' : ''}`}>
              <div className='nav-select-top'></div>
              <div className={`nav-account-btn ${'Payment' === selectedOption ? 'active-btn' : ''}`} onClick={() => handleOptionClick('Payment')}><Creditcard width={"18px"} height={"18px"} /> Payment</div>
              <div className='nav-select-bottom'></div>
            </div>
            <div className={`${'Invoice' === selectedOption ? 'account_middle' : ''}`}>
              <div className='nav-select-top'></div>
              <div className={`nav-account-btn ${'Invoice' === selectedOption ? 'active-btn' : ''}`} onClick={() => {handleOptionClick('Invoice');fetchInvoices();}}><Invoiceicon width={"18px"} height={"18px"} /> Invoice</div>
              <div className='nav-select-bottom'></div>
            </div>
            <div className={`${'Close Account' === selectedOption ? 'bottom_account' : ''}`}>
              <div className='nav-select-top'></div>
              <div className={`nav-account-btn ${'Close Account' === selectedOption ? 'active-btn-bottom' : ''}`} onClick={() => handleOptionClick('Close Account')}><Delete width={"18px"} height={"18px"} />Account</div>
            </div>
          </div>
        </div>

        {selectedOption === 'Profile' && (
        <div className='profile_page_loader'>
          <div className='user_data_profile'>
          {selectedOption === 'Profile' && (
              <div className='account-page-profile'>              
                <h2>Profile</h2>
                {showEdit ? (
                // <button className='edit_button_profile' onMouseMove={handleMouseMove} onClick={handleCancelEdit}>Cancel
                //   <div className='gradient-overlay' style={{ background: `radial-gradient(circle at ${cursorPosition.x}px ${cursorPosition.y}px,#0f5ba6, #3498db)` , alignItems:'center', justifyContent:'center', display:'flex'}}>Cancel</div>
                // </button>
                null
                ) : (
                // <button className='edit_button_profile' onClick={handleeditprofile}>Edit</button>
                <button className='edit_button_profile' onMouseMove={handleMouseMove} onClick={handleeditprofile}>Edit
                  <div className='gradient-overlay' style={{ background: `radial-gradient(circle at ${cursorPosition.x}px ${cursorPosition.y}px,#0f5ba6, #3498db)` , alignItems:'center', justifyContent:'center', display:'flex'}}>Edit</div>
                </button>
                )}
                { showEdit ? (
                  <div>
                    <section className='profile-data'>
                      <div>
                        <label>First Name</label>
                        <input className='edit-account-input' type='text'  value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                      </div>
                      <div>
                        <label>Last Name </label>
                        <input className='edit-account-input' type='text'  value={lastName} onChange={(e) => setLastName(e.target.value)} />
                      </div>
                      <div>
                        <label>DOB </label>
                        <input className='edit-account-input' type='text'  value={dob} onChange={(e) => setDob(e.target.value)} />
                      </div>
                      <div>
                        <label>Address </label>
                        <input className='edit-account-input' type='text'  value={address} onChange={(e) => setAddress(e.target.value)} />
                      </div>
                      <div>
                        <label>Email </label>
                        <input className='edit-account-input' type='text'  value={email} onChange={(e) => setEmail(e.target.value)} />
                      </div>
                      <div>
                        <label>Phone </label>
                        <input className='edit-account-input' type='text'  value={phone} onChange={(e) => setPhone(e.target.value)} />
                      </div>
                      
                      <div className='submit_container'>
                        <button className='submit_button_profile' onMouseMove={handleMouseMove} onClick={handleCancelEdit}>Cancel
                          <div className='gradient-overlay' style={{ background: `radial-gradient(circle at ${cursorPosition.x}px ${cursorPosition.y}px,#0f5ba6, #3498db)` , alignItems:'center', justifyContent:'center', display:'flex'}}>Cancel</div>
                        </button>
                        <div style={{ width: '20px' }}></div>
                        <button className='submit_button_profile' onMouseMove={handleMouseMove} onClick={handleEditacc}>Confirm
                          <div className='gradient-overlay' style={{ background: `radial-gradient(circle at ${cursorPosition.x}px ${cursorPosition.y}px,#0f5ba6, #3498db)` , alignItems:'center', justifyContent:'center', display:'flex'}}>Confirm</div>
                        </button>
                      </div>

                    </section>
                  </div>
                ):(
                fetcheddata ? (
                <section className='profile-data'>
                  
                  <div className='Name_mainbox'>
                    <div className='namebox_userinfo'>
                      <label className='label_profile'>First Name</label>
                      <div className='line_userinfo'></div>
                      <p className='info_user'>{fetcheddata.firstName}</p>
                    </div>
                  </div>
                  <div className='Name_mainbox'>
                    <div className='namebox_userinfo'>
                      <label className='label_profile'>Last Name</label>
                      <div className='line_userinfo'></div>
                      <p className='info_user'>{fetcheddata.lastName}</p>
                    </div> 
                  </div>

                  <div className='Name_mainbox'>
                    <div className='dob_userinfo'>
                      <label className='label_profile'>DoB</label>
                      <div className='line_userinfo'></div>
                      <p className='info_user'>{fetcheddata.dob ? fetcheddata.dob.split('T')[0] : fetcheddata.dob}</p>
                    </div>
                  </div>
                  <div className='Name_mainbox'>
                    <div className='address_userinfo'>
                      <label className='label_profile'>Address</label>
                      <div className='line_userinfo'></div>
                      <p className='info_user'>{fetcheddata.address}</p>
                    </div>
                  </div>

                  <div className='Name_mainbox'>
                    <div className='email_userinfo'>
                      <label className='label_profile'>Email</label>
                      <div className='line_userinfo'></div>
                      <p className='info_user'>{fetcheddata.email}</p>
                    </div>
                  </div>
                  <div className='Name_mainbox'>
                    <div className='phone_userinfo'>
                      <label className='label_profile'>Phone</label>
                      <div className='line_userinfo'></div>
                      <p className='info_user'>{fetcheddata.phone}</p>
                    </div>
                  </div>
                  
                  {securitycode ?(
                    <div className='Name_mainbox'>
                      <div className='securitypasscode_userinfo'>
                        <label className='label_profile'>Security Code</label>
                        <div className='line_userinfo'></div>
                        <p className='info_user'>{fetcheddata.securitypasscode}</p>
                      </div>
                    </div>
                  ):(
                    <div className='Name_mainbox'>
                      <div className='securitypasscode_userinfo'>
                        <label className='label_profile'>Security Code</label>
                        <div className='line_userinfo'></div>
                        {/* <p className='info_user'>{fetcheddata.securitypasscode}</p> width={"20px"} height={"20px"} */}
                        {/* <p className='info_user'><button className='button_passcode' onClick={seepasscode}>View</button></p> */}
                        <p className='info_user'> ######## <LockEye  style={{marginLeft: '25px', cursor: 'pointer'} }  onClick={seepasscode} /></p>
                        
                      </div>
                    </div> 
                    )}
                    <div className='Name_mainbox'>
                      <div className='membership_userinfo'>
                        <label className='label_profile'>membership</label>
                        <div className='line_userinfo'></div>
                        <p className='info_user'>{fetcheddata.membership}</p>
                        <div className='svg_membership'><Update width={"18px"} height={"18px"} /></div>
                      </div>
                    </div>
                  <div className=''>
                  {fetcheddata.verification === 'pending' ? (
                    <div>
                      { verification === false || verification === 'false' ? (
                        <div className='Verify_button'>
                          <div className='button_back'><button className='button_verify' onClick={handleverification}>Verify</button></div>
                        </div>
                      ) : (
                        // <div className='verification_box'>
                        //   <label className={`${message ? 'error_message' : ''}`} >Verification {message} </label>
                        //   <input className={`${message ? 'error-border' : 'otp_input'}`} type='text'  maxLength='6'  value={verificationcode} onInput={checkinput} />
                        // </div>
                        <div className='verification_box'>
                          <label className={`${message ? 'error_message' : ''}`}>Verification {message} </label>
                            {verificationCodes.map((code, index) => (
                              <input key={index} className={`${message ? 'error-border' : 'otp_input'}`} type='text' maxLength='1' value={code} onChange={(e) => handleInputChange(index, e)} ref={inputRefs.current[index]} />
                            ))}
                          </div>
                      )}
                    </div>
                  ):(
                    <div className='Name_mainbox'>
                      <div className='verification_userinfo'>
                        <label className='label_profile'>Verification</label>
                        <div className='line_userinfo'></div>
                        <p className='info_user'>Account {fetcheddata.verification} <Verified style={{marginLeft:'15px'}}/></p>
                      </div>
                    </div>
                  )}
                  </div>

                </section>


                ) : (
                  <section className='profile-data'>
                  <p>Loading...</p>
                </section>
                )
              )}
              </div>
            )}
          </div>
          <div className='user_data_profile_side'>
            <h2>Quick Links</h2>
            <div className='show_links_quicklinks'>
              <h2>Need to get in touch?</h2>
              <Link className="quick_link_style" to="/contact-us">Contact Me</Link>
              <h2>Access and manage your billing.</h2>
              <Link className="quick_link_style" onClick={() => setSelectedOption('Invoice')} >View Invoice</Link>
              <h2>Check your Messages</h2>
              <Link className="quick_link_style" to="/dashboard/message">Message</Link>
              <h2>Let's find a time that works for you.</h2>
              <Link className="quick_link_style" to="/dashboard">Book Appointment</Link>
              <h2>See what I can offer.</h2>
              <Link className="quick_link_style" to="/service">Services</Link>
            </div>
          </div>
        </div>
        )}

      {selectedOption !== 'Profile' && (
        <div className='user-data'>
          
        {selectedOption === 'Password' && (
          <div className='account-page-password'>
            <h2>Account Security Center</h2>
            {fetcheddata ? (
            <div>
              <section>
                <p>Password last Changed ON: {fetcheddata.lastpasswordchange ? fetcheddata.lastpasswordchange.split('T')[0] : fetcheddata.lastpasswordchange}</p>
              </section>

              <section className='expiry-password'>
                <div>
                  <label>Password Expires on</label> &nbsp;
                  {fetcheddata.expirydate ? fetcheddata.expirydate.split('T')[0] : fetcheddata.expirydate}
                </div>
              </section>

              <section>
                <p>Looking to change the password</p>
                <button className='button-show-changepassword' onMouseMove={handleMouseMove} onClick={()=> setShowChange(prevstate => !prevstate)}>{showChange ? 'Cancel' : 'Change Password'}
                <div className='gradient-overlay' style={{ background: `radial-gradient(circle at ${cursorPosition.x}px ${cursorPosition.y}px,#0f5ba6, #3498db)` , alignItems:'center', justifyContent:'center', display:'flex'}}>{showChange ? 'Cancel' : 'Change Password'}</div>
                </button>
              </section>

              {showChange && (
              <div>
                <div>
                  <h3>Password Strength Guidelines</h3>
                  <p>To ensure the security of your account, follow these guidelines when creating a new password:</p>
                  <ul className='guidelines_password'>
                    <li><b>Length: </b>Your password should be at least 12 characters long.</li>
                    <li><b>Complexity:</b> Use a mix of uppercase and lowercase letters, numbers, and special characters.</li>
                    <li><b>Unpredictability: </b>Avoid using easily guessable information, such as names or birthdays.</li>
                  </ul>
                  <h3>Tips for a Secure Password</h3>
                  <ul className='guidelines_password'>
                    <li><b>Unique Passwords:</b> Avoid using the same password across multiple platforms.</li>
                    <li><b>Password Managers:</b> Consider using a reputable password manager to generate and store complex passwords securely.</li>
                  </ul>
                </div>
                <form >
                <section className='password-account-page'>
                  <label htmlFor='currentpassword'>Current Password</label>
                  <div className='input-password'>
                  <input className='passwordchange_input' type='password' id='currentpassword' value={currentpassword} onChange={(e) => setCurrentpassword(e.target.value)} ></input>
                  </div>
                </section>

                <section className='password-account-page'>
                  <label htmlFor='NewPassword'>New Password</label>
                  <div className='input-password'>
                    <input className={passwordequal && passwordstrength === 'strong' ? 'success_match' : passwordequal && passwordstrength === 'medium' ? 'medium_match' : passwordequal && passwordstrength === 'weak' ? 'weak_match' : 'passwordchange_input'} type={showPassword ? 'text' : 'password'} id='NewPassword' value={newpassword} onChange={(e) => setNewpassword(e.target.value)} />
                    {newpassword !== '' ? (showPassword ?  <CloseEye className='eye-password' width={"18px"} height={"18px"} onClick={()=>setShowPassword(false)}/> : <Eye className='eye-password' width={"18px"} height={"18px"} onClick={()=>setShowPassword(true)}/>) :(null)}
                  </div>
                </section>
                
                <section className='password-account-page'>
                  <label htmlFor='confirmNewPassword'>Confirm Password</label>
                  <div className='input-password'>
                    <input className={passwordequal && passwordstrength === 'strong' ? 'success_match' : passwordequal && passwordstrength === 'medium' ? 'medium_match' : passwordequal && passwordstrength === 'weak' ? 'weak_match' : 'passwordchange_input'} type={showPassword ? 'text' : 'password'} id='confirmNewPassword' value={confirmnewpassword} onChange={(e) => setConfirmnewpassword(e.target.value)} />
                    {confirmnewpassword !== '' ? (showPassword ?  <CloseEye className='eye-password' width={"18px"} height={"18px"} onClick={()=>setShowPassword(false)}/> : <Eye className='eye-password' width={"18px"} height={"18px"} onClick={()=>setShowPassword(true)}/>) :(null)}
                  </div>
                </section>

                <section className='password-account-page'>
                  {/* {newpassword && confirmnewpassword && <span className='match-password'>{passwordMatch()}</span>} */}
                  {passwordequal && <span className='strength'>Password Strength : {getPasswordStrength(newpassword, confirmnewpassword)}</span>}
                </section>
                

                <section  className='password-account-page'>
                  <button className={!confirmpasswordbutton ? 'submit-change-password' : 'disabled_submit_change'} onMouseMove={!confirmpasswordbutton ? handleMouseMove : null} onClick={handlechangePassword} disabled= {confirmpasswordbutton} >Change Password
                    <div className='gradient-overlay' style={{ background: `radial-gradient(circle at ${cursorPosition.x}px ${cursorPosition.y}px,#0f5ba6, #3498db)` , alignItems:'center', justifyContent:'center', display:'flex'}}>Change Password</div>
                  </button>
                </section>
                </form>
                
                <section>
                  <p>Tired of creating a new password everytime. Let us create a Strong Password for you</p>
                </section>

                <section  className='password-account-page'>
                  <button className='generate-password' onMouseMove={handleMouseMove} onClick={generatePassword}>Generate Strong Password
                  <div className='gradient-overlay' style={{ background: `radial-gradient(circle at ${cursorPosition.x}px ${cursorPosition.y}px,#0f5ba6, #3498db)` , alignItems:'center', justifyContent:'center', display:'flex'}}>Generate Strong Password</div>
                  </button>
                </section>
              </div>
              )}

              
              <div className='line-between-profiledata'></div>

              <section className='password-account-lockout'>
                
                <h3>Account Lock Feature</h3>
                <p>If you suspect any unauthorized activity or want to add an extra layer of security, you can choose to lock your account temporarily. This will prevent access until you decide to unlock it.</p>
                
                <div style={{display:'flex', gap:'10px', alignItems:'center'}}>
                  {accountLock ? <Lock /> : <Unlock /> }
                  {accountLock ? (
                    <button className='lock-out' onClick={handleunlock}>Unlock Account</button>
                  ):(
                    <button className='lock-out' onClick={handlelock} onMouseMove={handleMouseMove} > Lock Account
                      <div className='gradient-overlay' style={{ background: `radial-gradient(circle at ${cursorPosition.x}px ${cursorPosition.y}px,#0f5ba6, #3498db)` , alignItems:'center', justifyContent:'center', display:'flex'}}>Lock Account</div>
                    </button>
                  )}
                </div>

                <div>
                  <h4>Why Lock Your Account?</h4>
                  <ul className='guidelines_password'>
                    <li><b>Suspicious Activity:</b> Lock your account if you notice any unfamiliar activity.</li>
                    <li><b>Device Loss or Theft: </b>Secure your account in case your device is lost or stolen.</li>
                  </ul>
                  <p><b>Note: </b>Locking your account is reversible. You can unlock it by verifying your identity through the account recovery process.</p>
                </div>
                
              </section>

              
            </div>
            ): (
            <div>
              <p>Loading...</p>
            </div>
            )}
            

          </div>
        )}

        {selectedOption === 'Address Card' && (
          <div className='account-page-addresscard'>
            <h2>Top-Secret Stash: Because Your Addresses Deserve a VIP Lounge</h2>
            <section>

              {!addaddresscard && (<div style={{marginBottom:'20px'}}>
                <button className='address_card_add' onMouseMove={handleMouseMove} onClick={(e) => {setAddaddresscard(true)}}>Add New Address
                  <div className='gradient-overlay' style={{ background: `radial-gradient(circle at ${cursorPosition.x}px ${cursorPosition.y}px,#0f5ba6, #3498db)` , alignItems:'center', justifyContent:'center', display:'flex'}}>Add New Address</div>
                </button>
              </div>)}

             { addaddresscard && ( 
              <div>
                <form className='form-addresscard'>
                  <div>
                    <label>Name</label>
                    <input type='text' className={isFieldEmpty('name') ? 'empty_fields' : 'addressinput'} value={name} onBlur={() => handleBlur('name')} onChange={(e) => setName(e.target.value)} />
                  </div>
                  <div>
                    <label>Address</label>
                    <input type='text' className={isFieldEmpty('address1') ? 'empty_fields' : 'addressinput'} value={address1} onBlur={() => handleBlur('address1')} onChange={(e) => setAddress1(e.target.value)} />
                  </div>
                  <div>
                    <label>City</label>
                    <input type='text' className={isFieldEmpty('city') ? 'empty_fields' : 'addressinput'} value={city} onBlur={() => handleBlur('city')} onChange={(e) => setCity(e.target.value)} />
                  </div>
                  
                  <div className='row-address'>
                    <div>
                      <label>Type</label>
                      <select value={selectedType}  className={isFieldEmpty('selectedType') ? 'empty_fields' : 'addressinput'} onBlur={() => handleBlur('selectedType')} onChange={(e) => setSelectedType(e.target.value)} >
                        <option value=''>Select an option</option>
                        <option value='Home'>Home</option>
                        <option value='Billing'>Billing</option>
                        <option value='Work'>Work</option>
                        <option value='Office'>Office</option>
                      </select>
                    </div>
                    <div style={{ position: 'relative' }}>
                      <label>State</label>
                      <input style={{ zIndex: 2 }} type='text' className={isFieldEmpty('state') ? 'empty_fields' : 'addressinput'} value={state} onBlur={() => handleBlur('state')} onChange={handleInpuChange} />
                      {suggestions.length > 0 && (
                        <ul className='ul_suggestion' style={{ zIndex: 1 }}>
                          {suggestions.map((suggestion, index) => (
                            <li className='list_suggestion_state' key={index} onClick={() => handleSuggestionClick(suggestion)}>
                              {suggestion}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                    <div>
                      <label>Postal Code</label>
                      <input type='text' className={isFieldEmpty('postalCode') ? 'empty_fields' : 'addressinput'} value={postalCode} onBlur={() => handleBlur('postalCode')} onChange={(e) => setPostalCode(e.target.value)} onInput={(e) => e.target.value = e.target.value.replace(/\D/g, '').slice(0, 5)}  />
                    </div>
                  </div>
                  <div className='addaddresscardbuttons'>
                    <button className={!addressnewsubmit ? 'button_addresscard' : 'disabled_submit_change'} onMouseMove={addressnewsubmit ? null : handleMouseMove} onClick={SaveAddressCard} disabled={addressnewsubmit}>Save Address
                      <div className='gradient-overlay' style={{ background: `radial-gradient(circle at ${cursorPosition.x}px ${cursorPosition.y}px,#0f5ba6, #3498db)` , alignItems:'center', justifyContent:'center', display:'flex'}}>Save Address</div>
                    </button>
                    <button className='button_addresscard' onMouseMove={handleMouseMove} onClick={canceladdresscard}>Cancel
                      <div className='gradient-overlay' style={{ background: `radial-gradient(circle at ${cursorPosition.x}px ${cursorPosition.y}px,#0f5ba6, #3498db)` , alignItems:'center', justifyContent:'center', display:'flex'}}>Cancel</div>
                    </button>
                  </div>
                  
                </form>
                <div className='line-between-profiledata'></div>
              </div>)}
              
              {editedAddress && (
                <div>
                  <h2>Edit Address</h2>
                  <form className='form-addresscard'>
                    <div>
                      <label>Name</label>
                      <input className='addressinput' type='text' value={editedAddress.name} onChange={(e) => setEditedAddress({...editedAddress, name: e.target.value})} />
                    </div>
                    <div>
                      <label>Address</label>
                      <input className='addressinput' type='text' value={editedAddress.address} onChange={(e) => setEditedAddress({ ...editedAddress, address: e.target.value })} />
                    </div>
                    <div>
                      <label>City</label>
                      <input className='addressinput' type='text' value={editedAddress.city} onChange={(e) => setEditedAddress({ ...editedAddress, city: e.target.value })} />
                    </div>
                    <div className='row-address'>
                      <div>
                        <label>Type</label>
                        <select className='addressinput' value={editedAddress.selectedType} onChange={(e) => setEditedAddress({ ...editedAddress, selectedType: e.target.value })}>
                          <option value=''>Select an option</option>
                          <option value='Home'>Home</option>
                          <option value='Billing'>Billing</option>
                          <option value='Work'>Work</option>
                          <option value='Office'>Office</option>
                        </select>
                      </div>
                      <div>
                        <label>State</label>
                        <input className='addressinput' type='text' value={editedAddress.state} onChange={(e) => setEditedAddress({ ...editedAddress, state: e.target.value })} />
                      </div>
                      <div>
                        <label>Postal Code</label>
                        <input className='addressinput' type='text' value={editedAddress.postalCode} onChange={(e) => setEditedAddress({ ...editedAddress, postalCode: e.target.value })} onInput={(e) => e.target.value = e.target.value.slice(0, 5)} />
                      </div>
                    </div>
                  </form>

                  <div className='button_edit_address'>
                    <button className='address_card_add' onMouseMove={handleMouseMove} onClick={handleUpdateAddress}>Update Address
                      <div className='gradient-overlay' style={{ background: `radial-gradient(circle at ${cursorPosition.x}px ${cursorPosition.y}px,#0f5ba6, #3498db)` , alignItems:'center', justifyContent:'center', display:'flex'}}>Update Address</div>
                    </button>
                    <button className='address_card_add' onMouseMove={handleMouseMove} onClick={handleCancelEdit1}>Cancel
                      <div className='gradient-overlay' style={{ background: `radial-gradient(circle at ${cursorPosition.x}px ${cursorPosition.y}px,#0f5ba6, #3498db)` , alignItems:'center', justifyContent:'center', display:'flex'}}>Cancel</div>
                    </button>
                  </div>

                  <div className='line-between-profiledata'></div>

                </div>
              )}

              <div className="address-list">
                <h2>Address Book</h2>
                
               {addresses.length === 0 ? (
                <>
                  <h3>No Address cards have been added</h3>
                  <p className='address_empty'>
                    It looks like you're ready to embark on a remodeling or renovation journey! To get started, let's add an address to your address book. Click the "Add New Address" button above to provide the location where you'd like the magic to happen. We can't wait to bring your dream space to life!
                  </p>
                </>
                ):(
                  <ul className="addresses">
                    {addresses && addresses.map((addr, index) => (
                      <li key={index} className="address-item">
                        <strong>{addr.type}</strong> 
                        <div className="address-details">
                          <div className="icons">
                            <Edit className='edit-icons' onClick={() => handleEditAddress(addr)} />
                            <Delete className='delete-icons' onClick={() => deleteAddress(addr._id)}/>
                          </div>
                          <p>{addr.name}</p>
                          <p>{addr.address}</p>
                          <p>{addr.city}, {addr.state}, {addr.postalCode}</p>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </section>
          </div>
        )}

        {selectedOption === 'Payment' && (
          <div className='account-page'>
            <h2>Secure Payment Hub</h2>

            <div className='payment_message'>
              <h3>Currently, we accept direct payments in cash.</h3>
              <p>
                <strong>Exciting news:</strong> we're in the process of introducing online payments soon,
                and the best part is,<strong> No need to add card details for now!</strong> 
              </p>
              <p>
                Stay tuned for updates as we strive to enhance your payment options.
                Thank you for your patience and continued support!
              </p>
            </div>

            <div>

              <section>
               {addcard ? ( 
               <div>
                  <form className='credit-card-form'>
                    <div>
                    <input className='credit-form' type='text' placeholder='Card Name' value={cardName} onChange={(e)=>{setCardName(e.target.value)}} />
                    </div>
                    <div>
                    <input className='credit-form' type='text' placeholder='Card Number' value={cardNumber} onChange={handleCardNumberChange} maxLength="19" />
                    </div>
                    <div>
                    <input className='credit-form' type='text' placeholder='MM/YY'value={cardexpiry} onChange={handleexpirydate} maxLength="5"/>
                    </div>
                    <div>
                    <input className='credit-form' type='number' value={cvv} placeholder='CVV' min='000' max='999' onChange={(e)=>{setCvv(e.target.value.slice(0, 3))}}/>
                    </div>
                    <button className='payment_card' onMouseMove={handleMouseMove} onClick={handlesavecard}>Save Card
                      <div className='gradient-overlay' style={{ background: `radial-gradient(circle at ${cursorPosition.x}px ${cursorPosition.y}px,#0f5ba6, #3498db)` , alignItems:'center', justifyContent:'center', display:'flex'}}>Save Card</div>
                    </button>
                    {/* <button className='credit-submit-btn' onClick={handlesavecard}>Save Card</button>  */}
                    <button className='payment_card' onMouseMove={handleMouseMove} onClick={handlecancelcard}>Cancel Card
                      <div className='gradient-overlay' style={{ background: `radial-gradient(circle at ${cursorPosition.x}px ${cursorPosition.y}px,#0f5ba6, #3498db)` , alignItems:'center', justifyContent:'center', display:'flex'}}>Cancel Card</div>
                    </button>
                    {/* <button type="button" className='credit-submit-btn' onClick={handlecancelcard}>Cancel Card</button> */}
                  </form>
                </div>
                ) : (
                <div>
                  {/* <button className='credit-submit-btn' onClick={handlenewcard} >Add Card</button> */}
                  <button className='payment_card' onMouseMove={handleMouseMove} onClick={handlenewcard}>Add Card
                    <div className='gradient-overlay' style={{ background: `radial-gradient(circle at ${cursorPosition.x}px ${cursorPosition.y}px,#0f5ba6, #3498db)` , alignItems:'center', justifyContent:'center', display:'flex'}}>Add Card</div>
                  </button>
                </div>)}
              </section>

              {cards.length !== 0 ? (
                <div className="card-stack">
                  {cards.map((card, index) => (
                    <div
                      key={index}
                      className={`credit-card ${selectedCard === index ? "selected" : ""}`}
                      onClick={() => handleCardClick(index)}
                    >
                      <div className="card-front">
                        {showCVVPopup && selectedCard === index ? (
                          <div className="cvv-popup">
                            <div className="cvv-popup-content">
                              <div className="cvv-popup-message">Enter CVV to view the card details</div>
                              <input type="password" className="cvv-input" value={cvvInput} onChange={(e) => {setCVVInput(e.target.value.replace(/\D/g, ""))}} maxLength="3" placeholder='CVV' />
                              <button className='button_cvv' onClick={handleCVVSubmit}>View</button>
                            </div>
                            <button className='cancelcvv' onClick={handleCVVcancel}>Cancel</button>
                            <button className='cancelcvv' onClick={() => handledeletecard(index)}>Delete</button>
                          </div>
                        ) : (
                          <>
                            <div className="card-holder">{selectedCard === index ? card.cardName : `${card.cardName} ${card.cardNumber.slice(-4)}`}</div>
                            <div className="card-chip" />
                            <div className="card-number">{selectedCard === index ? card.cardNumber : "#### #### #### ####"}</div>
                            <div className="card-expiry-cvv">
                              <div className="card-expiry">
                                <span>{selectedCard === index ? card.cardexpiry : "MM/YY"}</span>
                              </div>
                              <div className="card-cvv">{selectedCard === index ? card.cvv : "CVV"}</div>
                            </div>
                            <div className="card-logo">{card.cardlogo}</div>
                          </>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ) : null }

            </div>
            <div className='Credit_card_note'>
              <h3>Note: Secure Data Encryption</h3>
              <p>
                Your security is our top priority. When you enter your payment information, rest assured that we employ advanced encryption techniques to protect your data both during transmission and while stored in our database.
              </p>
              <p>
                If you have any questions or concerns, please contact us for more information.
              </p>
              <p>
                Thank you for trusting us with your transaction.
              </p>
              
            </div>
          </div>
        )}
        
        
        {selectedOption === 'Invoice' && (
              <div className='account-page'>
                <h2>Invoice</h2>
                <div className="invoice-summary-card">
                  <div className="invoice-section">
                    <div><Totalicon width={"50px"} height={"50px"} /></div>
                    <div>
                      <span className='section-title'>Total</span>
                      <span className='invoice_subtitle'>{invoices.length} Invoices</span>
                      <div className='invoice-tool'>
                        ${invoices.reduce((acc, invoice) => acc + parseFloat(invoice.total), 0).toFixed(2)}
                      </div>
                    </div>
                  </div>
                  <hr className="divider" />
                  <div className="invoice-section">
                    <div><Paidicon width={"50px"} height={"50px"} /></div>
                    <div>
                      <span className='section-title'>Paid</span>
                      <span className='invoice_subtitle'>{invoices.filter(invoice => invoice.status === 'Paid').length} Invoices</span>
                      <div className='invoice-tool Paid'>
                        ${invoices.reduce((acc, invoice) =>
                          invoice.status === 'Paid' ? acc + parseFloat(invoice.total) : acc, 0
                        ).toLocaleString('en-US', {minimumFractionDigits: 2})}
                      </div>
                    </div>
                  </div>
                  <hr className="divider" />
                  <div className="invoice-section">
                    <div><Pendingicon width={"50px"} height={"50px"} /></div>
                    <div>
                      <span className='section-title'>Pending</span>
                      <span className='invoice_subtitle'>{invoices.filter(invoice => invoice.status === 'Pending').length} Invoices</span>
                      <div className='invoice-tool Pending'>
                        ${invoices.reduce((acc, invoice) =>
                            invoice.status === 'Pending' ? acc + parseFloat(invoice.total) : acc, 0
                          ).toLocaleString('en-US', {minimumFractionDigits: 2})}
                      </div>
                    </div>
                  </div>
                  <hr className="divider" />
                  <div className="invoice-section">
                    <div><Overdueicon width={"50px"} height={"50px"} /></div>
                    <div>
                      <span className='section-title'>Overdue</span>
                      <span className='invoice_subtitle'>{invoices.filter(invoice => invoice.status === 'Overdue').length} Invoices</span>
                      <div className='invoice-tool Overdue'>
                        ${invoices.reduce((acc, invoice) =>
                            invoice.status === 'Overdue' ? acc + parseFloat(invoice.total) : acc, 0
                          ).toLocaleString('en-US', {minimumFractionDigits: 2})}
                      </div>
                    </div>
                  </div>
                </div>
                
                <button className='show_message' onClick={fetchInvoices}>Show Invoice</button>
                <input className='inovice_inputbox' type="text" value={searchQuery} onChange={handleSearchInputChange} placeholder="Search invoices..." />
                <div className="invoice_list_card">
                  <div className="invoice-heading">
                    <h3>Invoice number</h3>
                    <h3>Created</h3>
                    <h3>Due</h3>
                    <h3>Amount</h3>
                    <h3>Status</h3>
                  </div>
                  
                  {searchQuery.trim() === '' ? (
                      // Render all invoices when searchQuery is empty
                      invoices.map((invoice, index) => (
                        <div className="invoice-card" key={index}>
                          <h3>{invoice.invoiceNumber}</h3>
                          {/* <div className="invoice-detailsdifferent"> */}
                            <p>{invoice.invoiceDate.split('T')[0]}</p>
                            <p>{invoice.dueDate.split('T')[0]}</p>
                            <p>${parseFloat(invoice.total).toFixed(2)}</p>
                            {invoice.status === 'Paid' && <span className="status-badge paid">Paid</span>}
                            {invoice.status === 'Pending' && <span className="status-badge pending">Pending</span>}
                            {invoice.status === 'Overdue' && <span className="status-badge overdue">Overdue</span>}
                          {/* </div> */}
                          <div className="options_button positionbuttons" onClick={(e) => e.stopPropagation()}>
                            &#8226;&#8226;&#8226;
                            <div className="options_dropdown">
                              <button className='button_display_invoice' onClick={() => viewInvoice(invoice)}>View</button>
                              <button className='button_red_display_invoice' onClick={() => closeInvoice()}>close</button>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      // Render filtered invoices when searchQuery is not empty
                      filteredInvoices.map((invoice, index) => (
                        <div className="invoice-card" key={index}>
                          <div className="invoice-card" key={index}>
                          <h3>{invoice.invoiceNumber}</h3>
                          {/* <div className="invoice-detailsdifferent"> */}
                            <p>{invoice.invoiceDate.split('T')[0]}</p>
                            <p>{invoice.dueDate.split('T')[0]}</p>
                            <p>${parseFloat(invoice.total).toFixed(2)}</p>
                            {invoice.status === 'Paid' && <span className="status-badge paid">Paid</span>}
                            {invoice.status === 'Pending' && <span className="status-badge pending">Pending</span>}
                            {invoice.status === 'Overdue' && <span className="status-badge overdue">Overdue</span>}
                          {/* </div> */}
                          <div className="options_button positionbuttons" onClick={(e) => e.stopPropagation()}>
                            &#8226;&#8226;&#8226;
                            <div className="options_dropdown">
                              <button className='button_display_invoice' onClick={() => viewInvoice(invoice)}>View</button>
                              <button className='button_red_display_invoice' onClick={() => closeInvoice()}>close</button>
                            </div>
                          </div>
                        </div>
                        </div>
                      ))
                  )}

                  {/* {invoices.map((invoice, index) => (
                    <div className="invoice-card" key={index}>
                      <h3>{invoice.invoiceNumber}</h3>
                      
                        <p>{invoice.invoiceDate.split('T')[0]}</p>
                        <p>{invoice.dueDate.split('T')[0]}</p>
                        <p>${parseFloat(invoice.total).toFixed(2)}</p>
                        {invoice.status === 'Paid' && <span className="status-badge paid">Paid</span>}
                        {invoice.status === 'Pending' && <span className="status-badge pending">Pending</span>}
                        {invoice.status === 'Overdue' && <span className="status-badge overdue">Overdue</span>}
                      
                      <div className="options_button positionbuttons" onClick={(e) => e.stopPropagation()}>
                        &#8226;&#8226;&#8226;
                        <div className="options_dropdown">
                          <button className='button_display_invoice' onClick={() => viewInvoice(invoice)}>View</button>
                          <button className='button_red_display_invoice' onClick={() => closeInvoice()}>close</button>
                        </div>
                      </div>
                    </div>
                  ))} */}
                </div>
                 {selectedInvoice ? (
                 <div>
                    <div className="invoice-container">
                      <div className="invoice-header">
                        <img src={logo} alt="Company Logo" className="company-logo" />
                        <div className="company-info">
                          <h1>{selectedInvoice.companyName}</h1>
                          <p>{selectedInvoice.companyAddress}</p>
                          <p>{selectedInvoice.companyCity}, {selectedInvoice.companyState} {selectedInvoice.companyZipCode}</p>
                          <p>Phone: {selectedInvoice.companyPhone}</p>
                          <p>Email: {selectedInvoice.companyEmail}</p>
                          <p>Website: {selectedInvoice.companyWebsite}</p>
                        </div>
                      </div>
                
                      <div className="invoice-details">
                        <div className="invoice-info">
                          <h2>Invoice:</h2>
                          <p>Invoice Number: {selectedInvoice.invoiceNumber}</p>
                          <p>Invoice Date: {selectedInvoice.invoiceDate.split('T')[0]}</p>
                          <p>Due Date: {selectedInvoice.dueDate.split('T')[0]}</p>
                        </div>
                
                        <div className="billing-info">
                          <h2>Bill To:</h2>
                          <p>{selectedInvoice.customerName}</p>
                          <p>{selectedInvoice.customerAddress}</p>
                          <p>{selectedInvoice.customerCity}, {selectedInvoice.customerState} {selectedInvoice.customerZipCode}</p>
                          <p>Phone: {selectedInvoice.customerPhone}</p>
                          <p>Email: {selectedInvoice.customerEmail}</p>
                        </div>
                
                        {selectedInvoice.shippingAddress && (
                          <div className="shipping-info">
                            <h2>Ship To:</h2>
                            <p>{selectedInvoice.shippingAddress}</p>
                          </div>
                        )}
                      </div>
                
                      <table className="invoice-table">
                        <thead>
                          <tr>
                            <th>Item #</th>
                            <th>Description</th>
                            <th>Quantity</th>
                            <th>Unit Price</th>
                            <th>Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {selectedInvoice.items.map((item, index) => (
                            <tr key={index}>
                              <td>{item[`itemNumber_${index}`]}</td>
                              <td>{item[`description_${index}`]}</td>
                              <td>{item[`quantity_${index}`]}</td>
                              <td>{item[`unitPrice_${index}`]}</td>
                              <td>{item[`total_${index}`]}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                
                      <div className="invoice-summary">
                        <div className="invoice-totals">
                          <p>Subtotal: {selectedInvoice.subtotal}</p>
                          <p>Shipping: {selectedInvoice.shipping}</p>
                          <p>Tax ({selectedInvoice.taxPercentage}%): {selectedInvoice.taxAmount}</p>
                          <p>Total: {selectedInvoice.total}</p>
                        </div>
                      </div>
                
                      <div className="invoice-payment">
                        <h2>Payment Information:</h2>
                        <p>{selectedInvoice.paymentMethods}</p>
                        <p>{selectedInvoice.paymentInstructions}</p>
                      </div>
                
                      <div className="invoice-notes">
                        <h2>Notes:</h2>
                        <p>{selectedInvoice.notes}</p>
                      </div>
                
                      <div className="invoice-thank-you">
                        <p>Thank you for your business!</p>
                      </div>
                    </div>
                  </div>
                    ) : null}
                  </div>
                )}


                {selectedOption === 'Close Account' && (
                  <div className='account-page'>
                    <h2>Close Account</h2>
                    <p>You will lose access to your Miracle Roofing account once your deletion request has been submitted.</p>
                    {/* <button className='delete_account_btn' onClick={handleAccountDelete}>Delete Account</button> */}
                    <button className='payment_card' onMouseMove={handleMouseMove} onClick={handleAccountDelete}>Delete Account
                      <div className='gradient-overlay' style={{ background: `radial-gradient(circle at ${cursorPosition.x}px ${cursorPosition.y}px,#0f5ba6, #3498db)` , alignItems:'center', justifyContent:'center', display:'flex'}}>Delete Account</div>
                    </button>
                  </div>
            )}
      </div>
      )}
        


        </div>
    </div>
  )
}

export default Account