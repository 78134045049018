import React, { useState, useEffect } from 'react';
import solar_roof from '../../images/solarpanel.png'
import solar_panel from '../../images/solar.png'
import powercard from '../../images/carrange.png'
import solarhome from '../../images/solarhome.png'
import battery from '../../images/battery.png'
import image2 from '../../images/image2.png'
import house from '../../images/Solarhouse7.jpg'
import house1 from '../../images/Solarhouse8.jpg'
import house2 from '../../images/Solarhouse9.jpg'
import house3 from '../../images/solargenerate1.jpg'
import house4 from '../../images/solargenerate2.jpg'
import house5 from '../../images/solargenerate3.jpg'
import house6 from '../../images/solargenerate4.jpg'

import solarpanelcleaning from '../../images/SolarPanelCleaning.webp'
import electricrepair from '../../images/Electric_Repair.webp'
import outdoorpainting from '../../images/Outdoor_painting.webp'
import woodflooring from '../../images/Wood_Flooring.webp'
import drywallrepair from '../../images/Dry_Wall_Repair.webp'
import solarpanelmaintaince from '../../images/SolarPanel_Maintaince.webp'
import solarinstallation from '../../images/Solar_Installation.webp'

import ImageSlider from '../ImageSlider/ImageSlider';



import arrow from '../../images/ArrowSync.svg'
import sheild from '../../images/sheild.svg'
import databar from '../../images/databar.svg'
import attachmoney from '../../images/attach_money.svg'



// import './Solarroof.css';

function Solarroof() {

  const [currentImage, setCurrentImage] = useState(0);

  const images = [
    house,
    house1,
    house2,
    house3,
    house4,
    house5,
    house6
  ];
  const images_Slider = [
    { image: solarpanelcleaning, header: "Solar Panel Cleaning", text: "Look at how much brighter these panels are after a good cleaning!" },
    { image: electricrepair, header: "Electric Repair", text: "Electrical Upgrades That Add Value to Your Home." },
    { image: outdoorpainting, header: "Outdoor Painting", text: "Exterior Paint Colors Trending Right Now." },
    { image: woodflooring, header: "Wood & Flooring", text: "Offers timeless beauty and can be refinished many times." },
    { image: drywallrepair, header: "Dry Wall Repair", text: "The Ultimate Guide to Patching Drywall Like a Pro." },
    { image: solarpanelmaintaince, header: "Solar Panel maintenance", text: "A limited-time offer or discount can encourage people to act faster." },
    { image: solarinstallation, header: "Solar Installation", text: "Solar energy isn't an expense, it's an investment." },
  ];

  const nextImage = () => {
    setCurrentImage((currentImage + 1) % images.length);
  };

  const prevImage = () => {
    const newIndex = (currentImage - 1 + images.length) % images.length;
    setCurrentImage(newIndex);
  };

  useEffect(() => {
    const slidesElement = document.querySelector('.slides');
    let counter = 0;

    function nextSlide() {
      counter++;
      if (slidesElement) {
        slidesElement.style.transform = `translateX(-${counter * 100}%)`;

        if (counter === 2) {
          counter = -1;
        }
      }
    }

    const interval = setInterval(nextSlide, 10000);

    return () => clearInterval(interval);
  }, []); 

  return (
    <div>

      <section className='solarroof_section_1'>
        <div className='solarroof_section_content'>
          <div className='solar_heading'>
            <h1>Transforming Sunlight into Sustainable Power for a Brighter Tomorrow</h1>
          </div>
          <div className='image-home'>
            <div className="slider">
              <img src={images[currentImage]} alt=""  />
              <button className="prev" onClick={prevImage}>{"<"}</button>
              <button className="next" onClick={nextImage}>{">"}</button>
            </div>
          </div>
        </div>
        <div className='solar_subheading'>
          <p>Solar energy is essential for building a sustainable and secure energy future. As a clean, renewable resource, solar power reduces our reliance on fossil fuels, which significantly diminishes greenhouse gas emissions and combats climate change. By harnessing the sun's abundant energy, we decrease air and water pollution, safeguarding our health and the environment.  Moreover, solar power promotes energy independence, increasing grid resilience and offering homeowners potential savings on electricity costs. Embracing solar energy is a pivotal step towards creating a more responsible and self-sufficient society.</p>
        </div>
      </section>

      <section className="solarroof_section_2" >
        <ImageSlider items={images_Slider} />
      </section>

      <section className='solarroof_section_3'>
        <div className='solarroof_section_content'>
          <div className='solar_heading'>
            <img className='solarroof_image_section3' src={image2} alt='' />
          </div>
          <div className='image-home'>
            <div className='section3_heading'>Our offer to you</div>
            <div className='solar_section3'>
              <div className="left_solar_section3">
                <div className='solar_subtitle_section3'>
                  <img className="section3_svg" src={attachmoney} alt="Icon" />
                  <h2>Save on your bills</h2>
                  <p>Lower your monthly expenses with our smart solutions designed to optimize energy usage and minimize wastage.</p>
                </div>
                <div className='solar_subtitle_section3'>
                  <img className="section3_svg" src={sheild} alt="Icon" />
                  <h2>Take control of your home</h2>
                  <p>Transform your living space into a smart, intuitive environment where you're always in command.</p>
                </div>
              </div>
              <div className="right_solar_section3">
                <div className='solar_subtitle_section3'>
                  <img className="section3_svg" src={arrow} alt="Icon" />
                  <h2>environmentally friendly</h2>
                  <p>Track your project's environmental impact with our comprehensive carbon footprint calculator.</p>
                </div>
                <div className='solar_subtitle_section3'>
                  <img className="section3_svg" src={databar} alt="Icon" />
                  <h2>Invest in the future</h2>
                  <p>A sustainable investment that will pay for itself through savings on bills.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  );
}

export default Solarroof;