import React, { useEffect, useState } from 'react'
import './Service.css'

function Services() {
    const [services, setServices] = useState([]);

    const fetchServices = async () => {
        try {
          const response = await fetch(`http://localhost:3500/allservices`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "Access-Control-Allow-Origin": "*",
            },
          });
    
          const data = await response.json();
          if (data.status === 'ok') {
            setServices(data.data);
            console.log(data)
          }
        } catch (error) {
          console.error('Error fetching messages:', error);
        }
      };

      useEffect(()=>{
        fetchServices();
      },[])

  return (
    <div>
        <div className="service-list">
            <h1>All Services</h1>
            <div className="service-grid">
                {services.length > 0 ? (
                services.map((service) => (
                    <div className="service-card" key={service._id}>
                    <h2 className="service-title">{service.Service}</h2>
                    <p className="service_price">Price: <span>{service.Price}</span></p>
                    <p className="service_description">Description: <span>{service.Description}</span></p>
                    <p className="service_status">Status: <span>{service.Status ? 'Active' : 'Inactive'}</span></p>
                    </div>
                ))
                ) : (
                <div className="no-services"><p>No Services available</p></div>
                )}
            </div>
        </div>
    </div>
  )
}

export default Services