import React, { useEffect } from 'react'
// import './nomatch.css'
import { Link } from 'react-router-dom'

function Nomatch() {

  useEffect(()=>{
    const timer = setTimeout(()=> {
      window.location.href = '/';
    },5000)

    return ()=>{clearTimeout(timer)}
  },[])
  
  return (
    <>
      <div className='Nomatch-page-container'>
        <h1 className='heading-nomatch'>404 - Page Not Found</h1>
        <p className='description-nomatch'>
          Oops! The page you're looking for does not exist. Please check the URL or navigate back to the homepage.
        </p>

        
        <Link className='home-link-404' to={'/'}>Go to Homepage</Link>
      </div>
        
    </>
  )
}

export default Nomatch

