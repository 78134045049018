import React, { useEffect, useState } from 'react'

import { Link } from 'react-router-dom'
import { ReactComponent as HomeIcon } from '../../../component/images/house-solid.svg'
import { ReactComponent as AccountIcon } from '../../../component/images/gear.svg'
import { ReactComponent as MessageIcon } from '../../../component/images/inbox-solid.svg'
import { ReactComponent as LogoutIcon } from '../../../component/images/arrow-right-from-bracket-solid.svg'

import './Nav.css'

function Nav() {

    const useraccountid = window.localStorage.getItem("Id")
    const [count, setCount] = useState('')

    const token = window.localStorage.getItem("token")
    const headers =  {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization:token,
      "Access-Control-Allow-Origin": "*",
    }

    const fetchMessages = async () => {
        try {
            const response = await fetch(`http://localhost:3500/messages/${useraccountid}`, {
            method: 'GET',
            headers: headers,
            });

            const data = await response.json();
            if (data.status === 'ok') {
            const messages = data.notify;
            const unreadMessages = messages.filter((message) => message.status === 'unread');
            setCount(unreadMessages.length);
            // console.log('Length-navbar:', unreadMessages.length);
            }
        } catch (error) {
            console.error('Error fetching messages:', error);
        }
    };

    const handleUpdateMessageCount = () => {
        fetchMessages();
    };

    useEffect(() => {
        if (useraccountid === null) {
            console.log("empty")
        } else {
            fetchMessages();
            document.addEventListener('messageRead', handleUpdateMessageCount);
        }

        return () => {
            document.removeEventListener('messageRead', handleUpdateMessageCount);
        };
        // eslint-disable-next-line
    }, [useraccountid]);

    useEffect(() => {
        const handleScroll = () => {
            var element = document.querySelector('.profile-user');
            if (window.scrollY > 0) {
                element.classList.add('profile-user-scrolled');
            } else {
                element.classList.remove('profile-user-scrolled');
            }
        };
        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const [selectedNav, setSelectedNav] = useState(localStorage.getItem('selectedNav') || 'Home');

    useEffect(() => {
        localStorage.setItem('selectedNav', selectedNav);
    }, [selectedNav]);

    const handleNavLinkClick = (navName) => {
        setSelectedNav(navName);
        localStorage.setItem('selectedNav', navName);
    };
      

  return (
    <>
        <nav className='dashboard-navbar'>
            <Link className={`${selectedNav === 'Home' ? 'nav_active' : ''}`} to={'/dashboard'} onClick={() => handleNavLinkClick('Home')}>
                <HomeIcon width={"18px"} height={"18px"}/> Home
            </Link>
            <Link className={`${selectedNav === 'Messages' ? 'nav_active' : ''}`} to={'/dashboard/message'} onClick={() => handleNavLinkClick('Messages')}>
                <div className="icon-container"><MessageIcon width={"18px"} height={"18px"}/>{count > 0 && <sup className="message-count">{count}</sup>}</div> Messages
            </Link>
            <Link className={`${selectedNav === 'Account' ? 'nav_active' : ''}`} to={'/dashboard/account'} onClick={() => handleNavLinkClick('Account')}>
                <AccountIcon width={"18px"} height={"18px"}/> Account
            </Link>
            <Link className={`${selectedNav === 'SignOut' ? 'nav_active' : ''}`} to={'/dashboard/Signout'} onClick={() => handleNavLinkClick('SignOut')}>
                <LogoutIcon width={"18px"} height={"18px"}/> Sign Out
            </Link>
        </nav>
    </>
  )
}

export default React.memo(Nav)