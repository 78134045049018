
import React, { useState, useEffect } from 'react';
import { ReactComponent as Nextcalendar } from '../../images/next.svg'
import { ReactComponent as Prevcalendar } from '../../images/previous.svg'

function BookAppointment({ useraccount }) {
  const [address, setAddress] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [service, setService] = useState('');
  const [message, setMessage] = useState('');
  const [availableSlots, setAvailableSlots] = useState([]);
  const [availableDates, setAvailableDates] = useState([]);
  const [availableAddress, setAvailableAddress] = useState([]);
  const [availableServices, setAvailableServices] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [clickedFields, setClickedFields] = useState({ time: false, address: false, service: false});
  
  const appointmentsubmit = date.trim() === '' || time.trim() === '' || service.trim() === '';

  
  const token = window.localStorage.getItem("token")
  const headers =  {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization:token,
    "Access-Control-Allow-Origin": "*",
  }

  useEffect(() => {
    fetchAvailableDates();
    fetchAvailableServices();
    fetchAddress();
    // eslint-disable-next-line
  }, []);

  const fetchAddress = async () => {
    try {
      const addResponse = await fetch("http://localhost:3500/getuseraddress", {
        method: "POST",
        headers:headers,
        body: JSON.stringify({
          accountid: useraccount,
        }),
      });
      const data = await addResponse.json();
      if (data.status === 'ok') {
        setAvailableAddress(data.data);
      }
      if(data.message === 'No addresses found for the user') {

        alert('No addresses found for the user. Please add the address in the settings')
      }
    } catch (error) {
      console.log('Error fetching Address data:', error);
    }
  };

  const fetchAvailableDates = async () => {
    try {
      const response = await fetch('http://localhost:3500/available-dates',{headers:headers});
      const dates = await response.json();
      setAvailableDates(dates);
    } catch (error) {
      console.error('Error fetching available dates:', error);
    }
  };

  const fetchAvailableServices = async () => {
    try {
      const response = await fetch('http://localhost:3500/available-services',{headers:headers});
      const services = await response.json();
      setAvailableServices(services);
    } catch (error) {
      console.error('Error fetching available services', error);
    }
  };

  const handleDateChange = async (selectedDate) => {
    try {
      setDate(selectedDate)
      const response = await fetch(`http://localhost:3500/available-slots/${selectedDate}`,{headers:headers,});
      const data = await response.json();
      const slots = data.map(slot => slot.time);
      setAvailableSlots(slots);
    } catch (error) {
      console.error('Error fetching available slots:', error);
    }
  };

  const handleDateClick = (day) => {
    // Set the selected date when a date is clicked
    setSelectedDate(day);
    handleDateChange(day); // Fetch available slots for the selected date if needed
  };

  const handleSubmit = async (e) => {
     e.preventDefault();
     console.log('submit pressed')

    if (time === '' || service === '' || address === null ) {
        setMessage('Fill all the fields')
    } else {
      // eslint-disable-next-line
        const appointmentdata = {
            address: address,
            date: date,
            time: time,
            service: service,
            accountid: useraccount,
        }
        try {
            const response = await fetch("http://localhost:3500/appointmentDetails", {
                method: "PUT",
                crossDomain: true,
                headers:headers,
                body: JSON.stringify({
                    address,
                    date,
                    time,
                    service,
                    useraccount,
                })
            });
            // eslint-disable-next-line
            const data = await response.json();
            if (response.ok) {
              console.log('Appointment Booked successfully');
              window.location.reload();
            }
        } catch (error) {
            console.error('Error occurred during appointment booking:', error);
        }
    }
  };


  const handleNextMonth = () => {
    setCurrentMonth((prevMonth) => (prevMonth + 1) % 12);
    setCurrentYear((prevYear) => (currentMonth === 11 ? prevYear + 1 : prevYear));
  };

  const handlePrevMonth = () => {
    setCurrentMonth((prevMonth) => (prevMonth === 0 ? 11 : prevMonth - 1));
    setCurrentYear((prevYear) => (currentMonth === 0 ? prevYear - 1 : prevYear));
  };

  function displayCurrentCalendar() {
    const now = new Date();
    const today = now.getDate();

    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
  
    const firstDayOfMonth = new Date(currentYear, currentMonth, 1);
    const numberOfDaysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();

    const startDay = firstDayOfMonth.getDay();

    let calendarGrid = [];
    let days = [];
    let dayOfMonth = 1;
    for (let i = 0; i < 6; i++) {
      for (let j = 0; j < 7; j++) {
        if ((i === 0 && j < startDay) || dayOfMonth > numberOfDaysInMonth) {
          days.push(<div key={`${i}-${j}`} className="empty-cell"></div>);
        } else {
          const currentDate = `${currentYear}-${(currentMonth + 1).toString().padStart(2, '0')}-${dayOfMonth.toString().padStart(2, '0')}`;
          const isToday = dayOfMonth === today && currentMonth === now.getMonth() && currentYear === now.getFullYear();

          const isSelected = currentDate === selectedDate;
          const isAvailableDate = availableDates.includes(currentDate);
          const dateParts = currentDate.split('-');
          const dateObject = new Date(Number(dateParts[0]), Number(dateParts[1]) - 1, Number(dateParts[2]));
          const isPastDate = new Date(dateObject) < now;
          const isWeekendDay = dateObject.getDay() === 0 || dateObject.getDay() === 6;

          const cellStyle = {
            backgroundColor: isToday ? '#1877f2' : isSelected && isAvailableDate ? '#3CB371' : isAvailableDate && !isPastDate ? 'lightblue' : !isAvailableDate && !isWeekendDay ? '#FFD3D3' : null ,
            cursor: isPastDate ? 'not-allowed' : !isAvailableDate ? 'not-allowed' : 'pointer',
            color: (isPastDate && !isToday) || (isWeekendDay && !isToday) ? 'gray' : isToday || (isToday && isWeekendDay) ? 'white' : 'black',
            fontWeight: 'bold',
          };
          
          days.push(
            <div key={`${i}-${j}`} className="calendar_cell" style={cellStyle} onClick={() => !isPastDate && isAvailableDate && handleDateClick(currentDate)} >
              {dayOfMonth}
            </div>
          );
          if (dayOfMonth <= numberOfDaysInMonth) {
            dayOfMonth++;
          } else {
            // Move to the next month
            dayOfMonth = 1;
            // eslint-disable-next-line 
            currentMonth = (currentMonth + 1) % 12;
            // eslint-disable-next-line 
            currentYear += currentMonth === 0 ? 1 : 0;
          }
        }
      }
      calendarGrid.push(
        <div key={i} className="calendar-row">
          {days}
        </div>
      );
      days = [];
    }

    return (
      <div className="calendar">
        <div className="calendar-header">
          <div className='prev-month-button' onClick={handlePrevMonth}><Prevcalendar /></div>
          <div className='Calendar_month'> {monthNames[currentMonth]} {currentYear} </div>
          <div className='next-month-button' onClick={handleNextMonth}><Nextcalendar /></div>
        </div>
        <div className="calendar-days">
          <div className="calendar-row">
            <div className='calendar_weekday'>Sun</div>
            <div className='calendar_weekday'>Mon</div>
            <div className='calendar_weekday'>Tue</div>
            <div className='calendar_weekday'>Wed</div>
            <div className='calendar_weekday'>Thu</div>
            <div className='calendar_weekday'>Fri</div>
            <div className='calendar_weekday'>Sat</div>
          </div>
          {calendarGrid}
        </div>
      </div>
    );
  }

  const handleClickEvent = (field) => {
    setClickedFields((oldfields) => ({
      ...oldfields,
      [field]: true,
    }));
  };

  const isFieldEmpty = (field) => {
    return clickedFields[field] && !getFieldValue(field);
  };

  const isFieldClicked = (field) => {
    return clickedFields[field]
  };

  const getFieldValue = (field) => {
    return {
      time,
      address,
      service
    }[field];
  };

  return (
    <div className='Book-appointment-container'>
      <div className='Book-appointment-wrapper'>
        <h2>Book an Appointment</h2>
        <form className='Book-appointment-form'>
          <div className='book-appointment-fields'>
            <select id='Address' className={isFieldClicked('address') ? (isFieldEmpty('address') ? 'empty_bookappointment_select' : 'success_bookappointment_select') : 'bookappointment_select'} onBlur={() => handleClickEvent('address')} value={JSON.stringify(address)} 
              onChange={(e) => {
                const selectedAddress = e.target.value;
                if (selectedAddress) {
                  setAddress(JSON.parse(selectedAddress));
                } else {
                  setAddress('');
                }
              }}>

              <option value=''>Select an Address</option>
              {availableAddress.map((addressObj) => (
                  <option key={addressObj._id} value={JSON.stringify(addressObj)}>
                  {`${addressObj.name}, ${addressObj.address}, ${addressObj.city}, ${addressObj.state} ${addressObj.postalCode}`}
                  </option>
              ))}
            </select>
          </div>
          {/* <div className='book-appointment-fields'>
            <select id='date' value={date} onChange={(e) => handleDateChange(e.target.value)}>
              <option value=''>Select a date</option>
              {availableDates.map((date) => (
                <option key={date} value={date}>
                  {date}
                </option>
              ))}
            </select>
          </div> */}
          <div className='book-appointment-fields'>
            <select id='time' className={isFieldClicked('time') ? (isFieldEmpty('time') ? 'empty_bookappointment_select' : 'success_bookappointment_select') : 'bookappointment_select'} onBlur={() => handleClickEvent('time')} value={time} onChange={(e) => setTime(e.target.value)}>
              <option value=''>Select a time slot</option>
              {availableSlots.map((slot) => (
                <option key={slot} value={slot}> {slot} </option>
              ))}
            </select>
          </div>
          <div className='book-appointment-fields'>
            <select id='service' className={isFieldClicked('service') ? (isFieldEmpty('service') ? 'empty_bookappointment_select' : 'success_bookappointment_select') : 'bookappointment_select'} onBlur={() => handleClickEvent('service')} value={service} onChange={(e) => setService(e.target.value)}>
              <option value=''>Select a Service</option>
                {availableServices.map((services) => (
                  <option key={services} value={services}> {services} </option>
                ))}
            </select>
          </div>
          <span className='error-appointment'>{message}</span>
          <button className={!appointmentsubmit ? 'Button-book-appointment' : 'disabled_book_submit'} onClick={handleSubmit} disabled={appointmentsubmit}> Book</button>
        </form>
      </div>
      <div className='calendar-date'>
        {displayCurrentCalendar()}
        
      </div>
        
    </div>
  );
}

export default BookAppointment;