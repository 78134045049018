import React from 'react';
import './About.css';

function About() {
  return (
    <section className='about-page'>
      <div className='about-content'>
        <header>
          <h1 className='company-name'>Miracle Roofing</h1>
        </header>

        <section className='company-description'>
          <h2>About Us</h2>
          <p>
            At Miracle Roofing, we are dedicated to providing exceptional services to our valued customers. With a strong commitment to quality, innovation, and customer satisfaction, we strive to be a leading company in the roofing and renovation industry.
          </p>
        </section>

        <section className='mission-and-offer'>
          <h2>Our Mission</h2>
          <p>
            Our mission is to transform our customers' dreams into reality by offering top-notch renovation services and cutting-edge solar roofing solutions. We aim to improve the quality of life for our customers by enhancing their living spaces and contributing to a sustainable future through solar energy.
          </p>

          <h2>What We Offer</h2>
          <p>
            Miracle Roofing specializes in comprehensive renovation services, including remodeling of office spaces, homes, kitchens, bedrooms, and interiors. In addition to renovation, we offer state-of-the-art solar roofing solutions. Our approach is to provide tailor-made solutions that meet the unique requirements of our customers, ensuring their satisfaction.
          </p>
        </section>

        <section className='why-choose-us'>
          <h2>Why Choose Us</h2>
          <ul>
            <li>
              <strong>Quality:</strong> We are committed to delivering top-quality renovation and solar roofing solutions. Our dedicated team ensures that every project meets rigorous standards and undergoes thorough quality checks.
            </li>
            <li>
              <strong>Innovation:</strong> We embrace innovation and continuously strive to improve and evolve our offerings. By staying at the forefront of industry advancements, we provide our customers with the latest and most effective solutions.
            </li>
            <li>
              <strong>Customer Focus:</strong> Our customers are at the heart of everything we do. We listen to their needs, understand their challenges, and tailor our renovation and solar roofing services to exceed their expectations. Your satisfaction is our top priority.
            </li>
            <li>
              <strong>Expert Team:</strong> Our team comprises highly skilled professionals with deep expertise in renovation and solar roofing. With their knowledge and experience, they are equipped to handle any project or requirement with precision and efficiency.
            </li>
            <li>
              <strong>Competitive Pricing:</strong> We believe that quality should not come at an exorbitant cost. Our pricing structure is designed to provide you with excellent value for your investment. We offer competitive prices without compromising on quality.
            </li>
          </ul>
        </section>

        <section className='sustainability-and-contact'>
          <h2>Our Commitment to Sustainability</h2>
          <p>
            At Miracle Roofing, we recognize the importance of sustainability. We are committed to minimizing our environmental impact by offering eco-friendly renovation materials and promoting solar energy adoption. By choosing us, you are supporting a company that values environmental responsibility and contributes to a greener future.
          </p>

          <h2>Get in Touch</h2>
          <p>
            Thank you for taking the time to learn about Miracle Roofing. We invite you to explore our website to discover our full range of renovation and solar roofing services. If you have any questions, need further information, or would like to discuss a project, our friendly team is here to assist you. Feel free to reach out to us via our contact information.
          </p>

          <p>We look forward to serving you and building a long-lasting relationship!</p>
        </section>
      </div>
    </section>
  );
}

export default About;
