import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// import './Forget.css'

function ForgetPassword() {

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [timer, setTimer] = useState(30);
  const [useremail, setUseremail] = useState('');
  const [passcode, setPasscode] = useState('')
  const [passcodesubmitted, setPasscodesubmitted] = useState(false)
  const [password, setPassword] = useState('')
  const [confirmpassword, setConfirmpassword] = useState('')
  const [accountid, setAccountid] = useState('')
  const [hideemailreset, setHideemailreset] = useState(true)



  const handleSubmit = async (e) => {
    e.preventDefault();

    const forgetpass = {
      email: email
    }
    console.log(forgetpass)

    if (email.trim() !== '') {
      setMessage(`An Email with a password reset link has been sent to ${email}`);
    } else {
      setMessage('No account found with the provided email address');
    }
    setSubmitted(true);
    startTimer();
  };

  const handleRetry = () => {
    setSubmitted(false);
    setMessage('');
  };

  const handleResend = () => {
    setMessage(`An Email with a password reset link has been sent to ${email}`);
    startTimer();
    const forgetpass = {
      email: email
    }
    console.log(forgetpass)
  };

  const isSubmitDisabled = email.trim() === '';

  const passcodesubmitDisabled = passcode.trim() === '' && useremail.trim() === '';

  const passwordsubmitDisabled = password === confirmpassword ? false : true;



  const startTimer = () => {
    setTimer(30);
  };

  useEffect(() => {
    let intervalId;
    if (timer > 0 && submitted) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      clearInterval(intervalId);
      setMessage('');
    }
    return () => clearInterval(intervalId);
  }, [timer, submitted]);

  const formatTime = () => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  const handlepasscodeSubmit = async(e) =>{
    e.preventDefault();
    try {
      const response = await fetch('http://localhost:3500/forgetpassword/checkpasscode', {
        method: "POST",
        crossDomain: true,
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({passcode, useremail}),
      });

      const data = await response.json();

      if (data.status === 'ok') {
        setMessage('Passcode has been successfully verified');
        setAccountid(data.data)
        setPasscodesubmitted(true);
        setHideemailreset(false);
      } 
    } catch (error) {
      console.error(' Error in Checking passcode', error);
    }
  }

  const handlepasswordchange = async (e) => {
    e.preventDefault();
    const currentdate= new Date()
      const IncreaseDays = () => {
        const newDate = new Date();
        newDate.setDate(newDate.getDate() + 250);
        return newDate;
      };
      const ExpiryDate = IncreaseDays()
      const lastpasswordchange = currentdate.toLocaleString()
      const newpassword = password

    if (accountid !== '') {
    try {
      const response = await fetch('http://localhost:3500/forgetpassword/changepassword/', {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ accountid, newpassword, ExpiryDate, lastpasswordchange }),
      });
  
      if (response.ok) {
        const data = await response.json();
        setMessage(data.message);
        setConfirmpassword('')
        setPassword('')
      } else {
        console.error('Error updating password:', response.statusText);
      }
    } catch (error) {
      console.error('Error updating password', error);
    }
    }
  };
  
  return (
    <div className='container-forgetpassword'>
        <div className="ForgetPassword">
            <h2>Forget Password</h2>
            {hideemailreset && (<div>
            {!submitted && (
                <p>
                    Forgot your password?<br/>
                    Enter your email and we'll send you a link to reset it
                </p>
            )}

            {!submitted ? (
              <form onSubmit={handleSubmit}>
                <label htmlFor="email">Email</label>
                <div className="input-button-container">
                  <input className='inputField_forgetpassword' type="email" id='email' value={email} onChange={(e) => setEmail(e.target.value)} required />
                  <button className='button_forget' type="submit" disabled={isSubmitDisabled}>Send Link</button>
                </div>
              </form>
            ) : (
              <div>
                <p className="message">{message}</p>

                {timer === 0 && <p>Did not receive Email? Try requesting for a new link</p>}
                {timer === 0 && <button className='button_forget' onClick={handleResend}>Request a new Link</button>}&nbsp;

                {timer > 0 && <p>Did not receive email? Check your spam folder !</p>}
                {timer > 0 && <p className="timer">New Request link will be available in: {formatTime()}</p>}
                <button className='button_forget' onClick={handleRetry}>Retry</button>
              </div>
            )}
                <div className="login-line-divider-div">
                  <div className="login-line-divider" data-label="or"></div>
                </div>

              </div>)}

                {!passcodesubmitted && (
                  <p>
                    Enter your security Passcode to reset the password
                  </p>
                )}

                {!passcodesubmitted ? (
                  <form onSubmit={handlepasscodeSubmit}>
                    <label htmlFor="useremail">Email</label>
                      <input className='inputField_forgetpassword' type="email" id='useremail' value={useremail} onChange={(e) => setUseremail(e.target.value)} required />
                    <label htmlFor="passcode">Passcode</label>
                      <input className='inputField_forgetpassword' type="text" id='passcode' value={passcode} onChange={(e) => setPasscode(e.target.value)} required />
                      <div className="input-button-container">
                        <button className='button_forget' type="submit" disabled={passcodesubmitDisabled}>Check Passcode</button>
                      </div>
                  </form>
                  ) : (
                  <div>
                    <p className="message">{message}</p>
                    <form onSubmit={handlepasswordchange}>
                      <label htmlFor="password">Password</label>
                        <input className='inputField_forgetpassword' type="password" id='password' value={password} onChange={(e) => setPassword(e.target.value)} required />
                      <label htmlFor="confirmpassword">Confirm Password</label>
                        <input className='inputField_forgetpassword' type="password" id='confirmpassword' value={confirmpassword} onChange={(e) => setConfirmpassword(e.target.value)} required />
                        <div className="input-button-container">
                          <button className='button_forget' type="submit" disabled={passwordsubmitDisabled}>Change Password</button>
                        </div>
                    </form>
                  </div>
                  )
                }
            <p>Back to <Link className='signup-link' to={'/login'}>Sign In</Link></p>
        </div>
    </div>
  );
}

export default ForgetPassword;
