import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
// import './login.css'


function Login() {
    const usernameref = useRef()
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    useEffect(() => {
        usernameref.current.focus();
    },[])

    const handleSubmit = async (e) => {
        e.preventDefault();

        if(username === '' || password === '') {
            console.log('fields are empty')

        } else {

            const logindata = {
                username: username,
                password: password
            };
            console.log(logindata)
            try {
                const response = await fetch("http://localhost:3500/login", {
                    method: "POST",
                    crossDomain: true,
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        "Access-Control-Allow-Origin": "*",
                    },
                    body: JSON.stringify({
                        email: username,
                        password,
                    }),
                });

                const data = await response.json();
                console.log(data, "userRegister");

                if (data.status === "ok") {
                    window.localStorage.setItem("token", data.data);
                    window.localStorage.setItem("Id",data.id );
                    window.localStorage.setItem("Verificationstatus",data.verificationstatus );
                    window.localStorage.setItem("Accountstatus",data.isNewAccount );
                    window.localStorage.setItem("Address_exist",data.isAddressExist)
                    console.log(data.isAddressExist)
                    window.localStorage.setItem("loggedInT", true);
                    // window.localStorage.setItem("firstName", data.firstName);

                    if(data.verificationstatus === false && data.isNewAccount === false && data.isAddressExist === true) {
                        window.location.href = "/dashboard";
                    } else if (data.verificationstatus === true || data.isNewAccount === true || data.isAddressExist === false) {
                        window.location.href = "/dashboard/account";
                    }
                    // window.location.href = "/dashboard";
                }
                else if(data.status === "WrongPassword"){
                    alert("Incorrect Credentials");
                }
                else if(data.status === "UserLocked"){
                    alert(`User Blocked \n Contact Support Team`)
                }
                else if(data.status === "User Account Deleted"){
                    alert(`User Account Deleted \n Contact Support Team`)
                }
                else if(data.status === "nodata"){
                    alert(`User not Found! \n Create New Account`)
                }
            } catch (error) {
                console.error('Error occurred during login:', error);
            }
        }
    }

    // async function handleSubmit(e) {
    //     e.preventDefault();
      
    //     if (username === '' || password === '') {
    //       console.log('fields are empty');
    //     } else {
    //       const logindata = {
    //         email: username,
    //         password: password,
    //       };
      
    //       try {
    //         const response = await fetch("http://localhost:3500/login", {
    //           method: "POST",
    //           crossDomain: true,
    //           headers: {
    //             "Content-Type": "application/json",
    //             Accept: "application/json",
    //             "Access-Control-Allow-Origin": "*",
    //           },
    //           body: JSON.stringify(logindata),
    //         });
      
    //         if (!response.ok) {
    //           throw new Error("Network response was not ok");
    //         }
      
    //         const data = await response.json();
    //         console.log(data, "userRegister");
      
    //         if (data.status === "ok") {
    //           alert("Login Successful");
    //           window.localStorage.setItem("token", data.data);
    //           // window.localStorage.setItem("firstName",data.firstName)
    //           window.location.href = "/dashboard";
    //         }
    //       } catch (error) {
    //         console.error("Error during login:", error);
    //       }
    //     }
    //   }
      

  return (
    <div className="container-login">
        <section className='login-container'>

            <form className="form-login" onSubmit={handleSubmit}>
                <div className='page-title'>
                    <h3 >Sign In</h3>
                </div>
                <div>
                    <label htmlFor='Username'>Username</label>
                    <input className='login_input' type='text' ref={usernameref} id='Username' value={username} onChange={(e) => setUsername(e.target.value)} ></input>
                </div>
                <div>
                    <label htmlFor='Password'>Password</label>
                    <input className='login_input' type='password' id='Password' value={password} onChange={(e) => setPassword(e.target.value)} ></input>
                </div>
                <button className='button_login' type="submit">Sign In</button>
                <div className="login-line-divider-div">
                    <div className="login-line-divider" data-label="or"></div>
                </div>
                <div className="links-login">
                    <Link className='login-link' to={'/forgetpassword'}>Forgot Password?</Link>
                    <Link className='login-link' to={'/signup'}>Create Account</Link>
                </div>
                
            </form>
        </section>
      
    </div>
  )
}

export default Login