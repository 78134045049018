import React, { useEffect, useRef, useState } from 'react'
import './Contact.css'
// import contact from '../../images/contact2p.png'

function Contact() {
    const Nameref = useRef();
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('')
    const [created, setCreated] = useState('')
    const [submissionSuccess, setSubmissionSuccess] = useState(null)
    const [sendingSubmission, setSendingSubmission] = useState(false)
    const [statusMessage, setStatusMessage] = useState('')

    useEffect(()=>{
        Nameref.current.focus();
    },[])

    useEffect(() => {
        if (submissionSuccess) {
            const timer = setTimeout(() => {

                setSubmissionSuccess(false);
                setName('');
                setEmail('');
                setPhone('');
                setMessage('');
                setCreated('');

            }, 500);

            return () => clearTimeout(timer);
        }
    }, [submissionSuccess]);

    useEffect(() => {
        if (statusMessage) {
            const timer1 = setTimeout(()=> {
                setStatusMessage('')
            },2000);

            return () => clearTimeout(timer1)
        }

    },[statusMessage])

    const handleSubmit = async (e) => {
        e.preventDefault();
        const timenow =  new Date();
        timenow.setHours(timenow.getHours() - 5);
        const dateString = timenow.toISOString();
        console.log(dateString)
        setCreated(dateString)
        const contactdata = {
            Name : name,
            Email : email,
            Phone : phone,
            Message: message,
            Created: dateString,
        };
        console.log(contactdata)
        setSendingSubmission(true)
        try {
            const response = await fetch("http://localhost:3500/contactUs", {
                method: "POST",
                crossDomain: true,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "Access-Control-Allow-Origin": "*",
                },
                body: JSON.stringify({
                    name,
                    email,
                    phone,
                    created,
                    message,
                })
            });


            const data = await response.json();
            console.log(data)
         if (response.ok) {
                setSubmissionSuccess(true);
                setSendingSubmission(false);
            } else {
                setSubmissionSuccess(false);
                setSendingSubmission(false);
                setStatusMessage('Failed to Submit: Please Try Again')
            }
        } catch (error) {
            console.error('Error occurred during contact submission:', error);
            setSubmissionSuccess(false);
            setSendingSubmission(false);
        }
    }

  return (
    <>
        <section className='contact_container'>
            {/* <div className='info_section'>
                <div className='contact_information'>
                    <h2 className='headinglarge'>CONTACT</h2>
                    <p>Let's Work Together</p>
                    <p>1911 West 15<sup>th</sup> Street<br/>Plano, TX 75075</p>
                    <br></br>
                    <p>Tel: +1 (972) 836-6770</p>
                    <br></br>
                    <p>info@miracleroofing.com</p>
                </div>
            </div> */}
            <div className='info_section'>
                <div className='contact_information'>
                    <h2 className='headinglarge'>CONTACT US</h2>
                    <p>Your queries matter to us. Reach out for any questions regarding our services or to schedule a consultation. Here's how you can connect with Miracle Roofing:</p>
                    <p><strong>Address:</strong><br/>
                    1911 West 15<sup>th</sup> Street<br/>Plano, TX 75075</p>
                    
                    <p><strong>Phone:</strong> +1 (972) 836-6770<br/>
                    <strong>Email:</strong> info@miracleroofing.com</p>
                    
                    <p>We're available from Monday to Friday, 9:00 AM to 5:00 PM. We strive to respond to all inquiries within 24 business hours.</p>
                </div>
            </div>
            <div className='form_section'>
                <form onSubmit={handleSubmit}>
                    <div className='contact_form'>
                        <input className='contact-field' type='text' ref={Nameref} name='name' autoComplete="name" placeholder='Name' value={name} onChange={(e)=>setName(e.target.value)} required></input>
                        <input className='contact-field' type='email' placeholder='Email' name='email' autoComplete="email" value={email} onChange={(e)=>setEmail(e.target.value)} required></input>
                        <input className='contact-field' type='tel' pattern="[0-9]{10}" name='phone' autoComplete="phone" placeholder='+1' value={phone} onChange={(e)=>setPhone(e.target.value)} required></input>
                        <textarea className='contact-field' placeholder='Message' name='message' autoComplete="message" rows="5" cols="50" value={message} onChange={(e)=>setMessage(e.target.value)} required></textarea>
                        <p className='contact_submission_error'>{statusMessage}</p>
                        <button className='button_contactsubmit' type='submit' disabled={sendingSubmission || submissionSuccess === true}> {sendingSubmission ? 'Sending...' : (submissionSuccess ? 'Sent' : 'Send')}</button>
                    </div>
                </form>
            </div>
        </section>
    </>
  )
}

export default Contact