import React, { useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
// import './signup.css'

function Signup() {
    const firstNameRef = useRef();

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmpassword, setConfirmpassword] = useState('')

    const [passwordmatch , setPasswordmatch] = useState(false)
    const [isagree, setIsagree] = useState(false)
    const [newsletter, setNewsletter] = useState(false)
    const [message, setMessage] = useState('')

    useEffect(() => {
        firstNameRef.current.focus();
    },[])

    useEffect(()=>{
        const passwordsconfirm = password === confirmpassword;
        setPasswordmatch(passwordsconfirm)

    },[password, confirmpassword])

    useEffect(() => {
        if(isagree){
            setMessage('')
        }
    },[isagree])

    const isallfilled = firstName.trim() === '' || lastName.trim() === '' || email.trim() === '' || password.trim() === '' || confirmpassword.trim() === '' || !passwordmatch;

    const handleSingup = async () => {

        if(isagree){
        
        const signupdata = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            password: password,
            newsletter: newsletter,
        };

        console.log(signupdata)
        try {
            const response = await fetch("http://localhost:3500/register", {
                method: "POST",
                crossDomain: true,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "Access-Control-Allow-Origin": "*",
                },
                body: JSON.stringify(signupdata)
            });

            const data = await response.json();
            console.log(data, "userRegistration");
        } catch (error) {
            console.error('Error occurred during signup:', error);
        }

    } else {
        setMessage('Agree to terms and condition to complete sign up')
    }

    }

        // async function handleSingup(e) {
    //  e.preventDefault();
      
    //     if (isagree) {
    //       const signupdata = {
    //         firstName: firstName,
    //         lastName: lastName,
    //         email: email,
    //         password: password,
    //         newsletter: newsletter,
    //       };
      
    //       try {
    //         const response = await fetch("http://localhost:3500/register", {
    //           method: "POST",
    //           crossDomain: true,
    //           headers: {
    //             "Content-Type": "application/json",
    //             Accept: "application/json",
    //             "Access-Control-Allow-Origin": "*",
    //           },
    //           body: JSON.stringify(signupdata),
    //         });
      
    //         if (!response.ok) {
    //           throw new Error("Network response was not ok");
    //         }
      
    //         const data = await response.json();
    //         console.log(data, "userRegistration");
    //       } catch (error) {
    //         console.error("Error registering user:", error);
    //       }
    //     } else {
    //       setMessage("Agree to terms and condition to complete sign up");
    //     }
    //   }


  return (
    <div className='container-signup'>
        <form className='form-signup' onSubmit={handleSingup}>
            <div className='sign-page-title'>
                <h3>Sign Up</h3>
            </div>
            <div>
                <label htmlFor='FirstName'>First Name</label>
                <input className='input-sign' type='text' ref={firstNameRef} value={firstName} id='FirstName' onChange={(e)=>setFirstName(e.target.value)} autoComplete='off' required/>
            </div>
            <div>
                <label htmlFor='LastName'>Last Name</label>
                <input className='input-sign' type='text' value={lastName} id='LastName' onChange={(e)=>setLastName(e.target.value)} autoComplete='off' required/>
            </div>
            <div>
                <label htmlFor='Email'>Email</label>
                <input className='input-sign' type='email' value={email} id='Email' onChange={(e)=>setEmail(e.target.value)} autoComplete='off' required/>
            </div>
            <div>
                <p className='signup_checkbox_para' onClick={() => setNewsletter(!newsletter)}>
                    <input type='checkbox' id='news_letter' checked={newsletter} onChange={(e) => setNewsletter(e.target.checked)} />
                &nbsp; Sign up to get product updates, news, and other marketing communications.</p>
            </div>
            <div>
                <label htmlFor='Password'>Password</label>
                <input className='input-sign' type='password' value={password} id='Password' onChange={(e)=>setPassword(e.target.value)} autoComplete='off' required/>
            </div>
            <div>
                <label htmlFor='ConfirmPassword'>Confirm Password</label>
                <input className='input-sign' type='password' value={confirmpassword} id='ConfirmPassword' onChange={(e)=>setConfirmpassword(e.target.value)} autoComplete='off' required/>
            </div>
            
            <div>
                <p className='signup_checkbox_para' onClick={() => setIsagree(!isagree)}>
                    <input type='checkbox' id='agreement' className='checkbox-input' checked={isagree} onChange={(e) => setIsagree(e.target.checked)} />
                &nbsp; By creating an account, I agree to the Terms and Privacy Policy.</p>
            </div>
            <span className='error-message'>{message}</span>
            <br/>
            <button className='button_signup' type="submit" disabled={isallfilled}>Sign Up</button>
            <div className="signup-line-divider-div">
                <div className="signup-line-divider" data-label="or"></div>
            </div>
            <div className="links-signup">
                <p>Already have an account?</p>
                <p><Link className='signup-link' to={'/login'}>Sign In</Link></p>
            </div>
        </form>
    </div>
  )
}

export default Signup