// eslint-disable-next-line
import React,{useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import Nav from '../Nav/Nav'

function Signout() {
  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch('http://localhost:3500/logout', {
        method: 'POST',
        headers: {
          'Authorization': token, // Send the JWT token for verification
        },
      });
      
      console.log('Fetch Response:', response); // Log the response
  
      if (response.status === 200) {
        // Successfully logged out on the server, now clear local storage and navigate to the login page
        localStorage.clear();
        navigate('/login');
      } else {
        // Handle logout error
        console.error('Logout failed');
      }
    } catch (error) {
      console.error('An error occurred during logout', error);
    }
  };
  

  // useEffect(() => {
  //   handleLogout();
  // }); 

  return (
    <div>
         <section className='profile-user'>
            <div>
              <Nav />
            </div>
        </section>

        <div className="page-content">
              <div className="logout-content">

              <div className="security-instructions">
                  <h3>Security Instructions:</h3>
                  <ul>
                    <li>Make sure you are in a secure and private location.</li>
                    <li>Double-check the URL to ensure you are on the official website.</li>
                    <li>Always log out when using public or shared computers.</li>
                    <li>Clear your browser history if you're using a public computer.</li>
                  </ul>
                </div>
                
                <h2>Logout Confirmation</h2>
                <p>Are you sure you want to logout?</p>
                <p>Logging out will end your current session.</p>
                
                
                
                <button className='button_logout' type="button" onClick={handleLogout}>Logout</button>
              </div>
            </div>      
    </div>
  )
}

export default Signout