
import { Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Navbar from './component/Navbar';
import Home from './component/pages/Home/Home';
import About from './component/pages/About/About';
import Login from './component/pages/Signin/Login';
import Signup from './component/pages/Signup/Signup';
import ForgetPassword from './component/pages/Forgetpassword/ForgetPassword'
import Footer from './component/Footer';
import Nomatch from './component/Nomatch';
import Solarroof from './component/pages/Solarroof/Solarroof';
import Renovation from './component/pages/Renovation/Renovation';
import Dashboard from './component/pages/Dashboard/Dashboard';
import Contact from './component/pages/ContactUs/Contact';
import Account from './component/pages/Account/Account';
import Message from './component/pages/Message/Message';
import Signout from './component/pages/Signout/Signout';
import { useState } from 'react';
import Services from './component/pages/Service/Services';



function App() {
  const isloggedIn = window.localStorage.getItem('loggedInT')
  // eslint-disable-next-line
  const [loggedIn, setLoggedIn] = useState(false)
  

  const location = useLocation()
  const signinup = location.pathname === '/login' || location.pathname === '/signup' || location.pathname === '/forgetpassword' || location.pathname === '/dashboard' || location.pathname === '/dashboard/account' || location.pathname === '/dashboard/message' || location.pathname === '/dashboard/Signout';
  const dashboard_pages = location.pathname === '/dashboard' || location.pathname === '/dashboard/account' || location.pathname === '/dashboard/message' || location.pathname === '/dashboard/Signout'
  
  return (
    <div className="App">
      {dashboard_pages ? null : <Navbar />}
      

      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/company/about' element={<About />} />
        <Route path='/Service' element={<Services />} />
        <Route path='/login' element={isloggedIn === "true" ? <Dashboard /> : <Login /> } />
        <Route path='/signup' element={<Signup />} />
        <Route path='/forgetpassword' element={<ForgetPassword />} />
        <Route path='/Solar-Roof' element={<Solarroof />} />
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/Renovation' element={<Renovation />} />
        <Route path='/contact-us' element={<Contact />} />
        <Route path='/dashboard/account' element={<Account />} />
        <Route path='/dashboard/message' element={<Message />} />
        <Route path='/dashboard/Signout' element={<Signout />} />

        <Route path='*' element={<Nomatch />} />
      </Routes>

      <div className="footer-app">
      {signinup ? null : <Footer loggedIn={loggedIn} />}
      </div>

      
    </div>
  );
}

export default App;
