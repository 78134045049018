import { useState } from "react";
import "./ImageSlider.css";
import photo_camera from '../../images/photo_camera.svg'

export const ImageSlider = ({ items }) => {
    const [active, setActive] = useState(3);
    const handleToggle = (index) => setActive(index);

    return (
        <div className="image-slider">
            {items.map((item, index) => {
                const isActive = active === index ? "active" : "";
                return (
                    <div key={item.image} className={`image-slider-item ${isActive}`} onClick={() => handleToggle(index)}>
                        <img src={item.image} alt="images" />
                        <div className="content">
                            <span className="material_symbols_outlined"><img className="icon_in_image" src={photo_camera} alt="camera icon" /></span>
                            <div className="slider_text">
                                <h1 className="SliderH1">{item.header}</h1>
                                <p className="SliderP">{item.text}</p>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default ImageSlider;
