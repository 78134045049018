import React, { useState, useEffect } from 'react';
import logo from '../../images/logo-fff.png'
import { ReactComponent as Filter } from '../../images/filter.svg'
import './Message.css'
import Nav from '../Nav/Nav';
import { Link } from 'react-router-dom';
import { ReactComponent as NextIcon } from '../../images/next.svg'
import { ReactComponent as PrevIcon } from '../../images/previous.svg'
import { ReactComponent as SearchIcon } from '../../images/dark_search.svg'
import { ReactComponent as MessageReadIcon } from '../../images/messageread.svg'
import { ReactComponent as MessageUnreadIcon } from '../../images/mark_as_unread.svg'
import { ReactComponent as TrashIcon } from '../../images/trashcan.svg'
import { ReactComponent as BackIcon } from '../../images/arrowleft1.svg'
import { ReactComponent as CloseIcon } from '../../images/xmark-solid.svg'
import { ReactComponent as SuccessIcon } from '../../images/success.svg'
import { ReactComponent as ErrorIcon } from '../../images/error.svg'


function Message() {

  const [messages, setMessages] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState('');
  const [useraccountid, setUseraccountid] = useState(window.localStorage.getItem("accountid"))
  const [invoice, setInvoice] = useState(null);
  const [filterstate, setFilterstate]= useState(false)
  const [filteredmessage, setFilteredmessage] = useState([]);
  const [filteredoption, setFilteredOption] = useState('');
  const [unreadOnly, setUnreadOnly] = useState(false);


  const [notifications, setNotifications] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const messagesPerPage = 14;
  const [openMessage, setOpenMessage] = useState(false);
  const [selectedMessageIndex, setSelectedMessageIndex] = useState('');
  const [searchMessageTerm, setSearchMessageTerm] = useState('');
  const [filterMessageButton, setFilterMessageButton] = useState(false);
  const [userMessages, setUserMessages] = useState([]);
  const [filteredMessages, setFilteredMessages] = useState([]);
  const [deletedMessagesList, setDeletedMessagesList] = useState([]);
  const [messageOption, setMessageOption] = useState('');
  const [deleteMessageButton, setDeleteMessageButton] = useState(false);
  const [showDeletedMessagesContainer, setShowDeletedMessagesContainer] = useState(false);
  
  const token = window.localStorage.getItem("token")
  const headers =  {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization:token,
    "Access-Control-Allow-Origin": "*",
  }

  const fetchData = async () => {
    try {
      const response = await fetch("http://localhost:3500/userData", {
        method: "POST",
        crossDomain: true,
        headers:headers,
        body: JSON.stringify({
          token: window.localStorage.getItem("token"),
        }),
      });

      const data = await response.json();
      if (data.status === 'ok') {
        setUseraccountid(data.data.accountid);
        console.log(data, "dashboard");
      }
    } catch (error) {
      console.log('Error fetching user data:', error);
    }
  };

  const fetchMessages = async () => {
    try {
      const response = await fetch(`http://localhost:3500/messages/${useraccountid}`, {
        method: 'GET',
        headers:headers,
      });

      const data = await response.json();
      if (data.status === 'ok') {
        // setMessages(data.notify);
        const UserMessagesData = data.notify;
        const activeMessages = UserMessagesData.filter(message => message.status !== 'deleted');
        const deletedMessages = UserMessagesData.filter(message => message.status === 'deleted');
        const sortedMessages = activeMessages.sort((a, b) => {
          return new Date(b.timestamp) - new Date(a.timestamp);
        });
        setUserMessages(sortedMessages)
        const sortedDeletedMessages = deletedMessages.sort((a, b) => {
          return new Date(b.timestamp) - new Date(a.timestamp);
        });
        setDeletedMessagesList(sortedDeletedMessages)
        console.log(sortedDeletedMessages)
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  useEffect(() => {
    if(useraccountid === null){
      fetchData();
    } else {
      fetchMessages();
    }
    // eslint-disable-next-line
  }, [useraccountid]);


  const handleCloseMessage = () => {
    setSelectedMessage(null);
    setInvoice(null);
  };

  const updateMessageStatus = async (messageId) => {
    try {
      const response = await fetch(`http://localhost:3500/messages/updateStatus/${messageId}`, {
        method: 'PUT',
        headers:headers,
        body: JSON.stringify({ status: 'read' }),
      });
  
      if (response.ok) {
        console.log('Message status updated to "read"');
        fetchMessages();
        // Dispatch the custom "message read" event to update the count
        const messageReadEvent = new Event('messageRead');
        document.dispatchEvent(messageReadEvent);

      } else {
        console.error('Error updating message status:', response.statusText);
      }
    } catch (error) {
      console.error('Error updating message status:', error);
    }
  };

  const updateMessage = async (messageId) => {
    try {
      const response = await fetch(`http://localhost:3500/messages/updateStatus/${messageId}`, {
        method: 'PUT',
        headers:headers,
        body: JSON.stringify({ status: 'unread' }),
      });
  
      if (response.ok) {
        console.log('Message status updated to "read"');
        fetchMessages();
        // Dispatch the custom "message read" event to update the count
        const messageReadEvent = new Event('messageRead');
        document.dispatchEvent(messageReadEvent);

      } else {
        console.error('Error updating message status:', response.statusText);
      }
    } catch (error) {
      console.error('Error updating message status:', error);
    }
  };  

  const handleDownload = () => {
    console.log('handle Download')
    
  }

  const handleDelete = async () => {
    if (selectedMessage) {
      try {
        const response = await fetch(`http://localhost:3500/messages/delete/${selectedMessage._id}`, {
          method: 'PUT',
          headers:headers,
          body: JSON.stringify({ status: 'deleted' }),
        });
    
        if (response.ok) {
          console.log('Message is deleted');
          setSelectedMessage('')
          fetchMessages();
        } else {
          console.error('Error deleting message ', response.statusText);
        }
      } catch (error) {
        console.error('Error deleting message', error);
      }
    }
  }

  const handleOpenMessage = (message) => {
    setSelectedMessage(message);
    if (message.status === 'unread') {
      updateMessageStatus(message._id); 
    } 
  };


  const fetchInvoice = async () => {
    try {
      const response = await fetch(`http://localhost:3500/getinvoice/${selectedMessage.invoice}`, {
          method: "POST",
          crossDomain: true,
          headers:headers,
          body: JSON.stringify({
            token: window.localStorage.getItem("token"),
          }),
        });

      if (response.ok) {
        const data = await response.json();
        console.log(data)
        setInvoice(data);
      } else {
        console.error('Failed to fetch invoice');
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const handleiconfilter = () => {
    setFilterstate(prevstate => !prevstate)
  }

  const handleFilter = (option) => {
    setFilteredOption(option);
    if (unreadOnly) {
      if (option === '') {
        const filteredMessages = messages.filter((message) => message.status.toLowerCase() === 'unread');
        setFilteredmessage(filteredMessages);
      } else {
      const filteredMessages = messages.filter((message) => message.type.toLowerCase() === option.toLowerCase() && message.status.toLowerCase() === 'unread');
      setFilteredmessage(filteredMessages);
      }
    } else {
      const filteredMessages = messages.filter((message) => message.type.toLowerCase() === option.toLowerCase());
      setFilteredmessage(filteredMessages);
    }
  };

  const handleUnreadFilter = (checked) => {
    setUnreadOnly(checked);
    if (filteredoption) {
      if (checked) {
        const filteredUnreadMessages = messages.filter((message) => message.status === "unread" && message.type.toLowerCase() === filteredoption.toLowerCase());
        setFilteredmessage(filteredUnreadMessages);
      } else {
        const filteredMessages = messages.filter((message) => message.type.toLowerCase() === filteredoption.toLowerCase());
        setFilteredmessage(filteredMessages);
      }
    } else {
      const filteredMessages = messages.filter((message) => message.status.toLowerCase() === 'unread');
      setFilteredmessage(filteredMessages);
    }
  };
  
  const [messageToggled, setMessageToggled] = useState(false);

  const handleToggle = () => {
    setMessageToggled(!messageToggled);
  };

  const removeNotification = (index) => {
    const updatedNotifications = [...notifications];
    updatedNotifications.splice(index, 1);
    setNotifications(updatedNotifications);
  };

  const handleMessageSearch = () => {
    console.log('filter clicked')
    if (searchMessageTerm.trim().length > 0) {
        const matchedMessages = userMessages.filter(message =>
          `${message.type}`.toLowerCase().includes(searchMessageTerm.trim().toLowerCase())
        );
    
        setFilteredMessages(matchedMessages);
    
        if (matchedMessages.length === 0) {
          const newNotification = {
            message: `No matches found for "${searchMessageTerm}"`,
            type: 'error'
          };
          setNotifications([newNotification, ...notifications]);
        } else {
            setFilterMessageButton(true)
        }
    } else {
        setFilteredMessages([]);
        setFilterMessageButton(false)
    }
  }

  const handleMessageClick = (message, listName) => {
    setSelectedMessage(message);
    updateMessageStatus(message._id); 
    setOpenMessage(true);

    let listToSearch = [];
    let messageOptionValue = '';

    switch (listName) {
        case 'userMessages':
            listToSearch = userMessages;
            messageOptionValue = 'userMessage';
            break;
        case 'filteredMessages':
            listToSearch = filteredMessages;
            messageOptionValue = 'filteredMessage';
            break;
        case 'deletedMessagesList':
            listToSearch = deletedMessagesList;
            messageOptionValue = 'deletedMessage';
            break;
        // case 'delFilteredMessagesList':
        //     listToSearch = delFilteredMessagesList;
        //     messageOptionValue = 'delfilteredMessage';
        //     break;
        default:
            console.warn('Invalid list name provided');
            return;
    }

    const indexInList = listToSearch.findIndex(m => m._id === message._id) + 1;

    if (indexInList > 0) {
        setSelectedMessageIndex(indexInList);
        setMessageOption(messageOptionValue);
    } else {
        console.warn('Message not found in the provided list');
    }
  };



  const goToNextCurrentMessage = () => {
    if (selectedMessageIndex < userMessages.length) {
        const nextMessageIndex = selectedMessageIndex;
        const nextMessage = userMessages[nextMessageIndex];
        setSelectedMessage(nextMessage);
        setSelectedMessageIndex(nextMessageIndex + 1);
        // setOpenMessage(true);
    }
  };

  const goToPrevCurrentMessage = () => {
    if (selectedMessageIndex > 1) {
        const prevMessageIndex = selectedMessageIndex - 2;
        const prevMessage = userMessages[prevMessageIndex];
        setSelectedMessage(prevMessage);
        setSelectedMessageIndex(prevMessageIndex + 1);
        // setOpenMessage(true);
    }
  };

  const goToNextFilteredMessage = () => {
    if (selectedMessageIndex < filteredMessages.length) {
        const nextMessageIndex = selectedMessageIndex;
        const nextMessage = filteredMessages[nextMessageIndex];
        setSelectedMessage(nextMessage);
        setSelectedMessageIndex(nextMessageIndex + 1);
        // setOpenMessage(true);
    }
  };

  const goToPrevFilteredMessage = () => {
    if (selectedMessageIndex > 1) {
        const prevMessageIndex = selectedMessageIndex - 2;
        const prevMessage = filteredMessages[prevMessageIndex];
        setSelectedMessage(prevMessage);
        setSelectedMessageIndex(prevMessageIndex + 1);
        // setOpenMessage(true);
    }
  };
  const goToPrevDeletedMessage = () => {
    if (selectedMessageIndex > 1) {
        const prevMessageIndex = selectedMessageIndex - 2;
        const prevMessage = deletedMessagesList[prevMessageIndex];
        setSelectedMessage(prevMessage);
        setSelectedMessageIndex(prevMessageIndex + 1);
        // setOpenMessage(true);
    }
  };
  const goToNextDeletedMessage = () => {
    if (selectedMessageIndex < deletedMessagesList.length) {
        const nextMessageIndex = selectedMessageIndex;
        const nextMessage = deletedMessagesList[nextMessageIndex];
        setSelectedMessage(nextMessage);
        setSelectedMessageIndex(nextMessageIndex + 1);
        // setOpenMessage(true);
    }
  };
    // Calculate the slice of messages to show
    const indexOfLastMessage = (currentPage + 1) * messagesPerPage;
    const indexOfFirstMessage = indexOfLastMessage - messagesPerPage;
    const currentMessages = userMessages.slice(indexOfFirstMessage, indexOfLastMessage);
    const filteredCurrentMessages = filteredMessages.slice(indexOfFirstMessage, indexOfLastMessage);
    const deletedCurrentMessages = deletedMessagesList.slice(indexOfFirstMessage, indexOfLastMessage);
    // const delFilteredCurrentMessages = delFilteredMessagesList.slice(indexOfFirstMessage, indexOfLastMessage);

    const goToNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    const goToPreviousPage = () => {
        setCurrentPage((prevPage) => prevPage - 1);
    };

    const handleShowDeletedMessages = () => {
      if (deletedMessagesList.length > 0){
          setShowDeletedMessagesContainer(true);
          setDeleteMessageButton(true);
      } else {
          const newNotification = {
              message: 'Trash is Empty !',
              type: 'error'
            };
      
          setNotifications([newNotification, ...notifications]);
      }
    }
  
    const handleMessageDelete = async (id) => {
      if (!id) {
        console.log('No message id provided');
        return;
      }
    
      try {
        const response = await fetch(`http://localhost:3500/messages/delete/${id}`, {
          method: 'PUT',
          headers: headers,
          body: JSON.stringify({ status: 'deleted' }),
        });

        const messageResponse = await response.json();
    
        if (response.ok) {
          setSelectedMessage('');
          setUserMessages(userMessages => userMessages.filter(message => message._id !== id));
          const newNotification = {
              message: messageResponse.message,
              type: 'success'
          };
          setNotifications([newNotification, ...notifications]);
          setSelectedMessage('')
          setOpenMessage(false)
        } else {
          const newNotification = {
              message: messageResponse.message,
              type: 'error'
          };
      
          setNotifications([newNotification, ...notifications]);
          console.error('Error deleting message', response.statusText);
        }
      } catch (error) {
        console.error('Error deleting message', error);
      }
    };

    const handleMessageUnRead = async (id) => {
      console.log(id)
      try {
          const response = await fetch(`http://localhost:3500/messages/updateStatus/${id}`, {
              method: 'PUT',
              headers:headers,
              body: JSON.stringify({ status: 'unread' }),
          });

          const messageResponse = await response.json();
      
          if (response.ok) {
              console.log('Message status updated to unread');
              const newNotification = {
                  message: messageResponse.message,
                  type: 'success'
              };
          
              setNotifications([newNotification, ...notifications]);

              setUserMessages(userMessages => userMessages.map(message => {
                  if (message._id === id) {
                      return { ...message, status: 'unread' }; 
                  }
                  return message;
              }));
              setSelectedMessage('')
              setOpenMessage(false)

          } else {
              const newNotification = {
                  message: messageResponse.message,
                  type: 'error'
                };
          
                setNotifications([newNotification, ...notifications]);
              console.error('Error updating message status:', response.statusText);
          }

      } catch (error) {
          console.error('Error updating message status:', error);
      }
    }

    const handleMessageRead = async (id) => {
      console.log(id)
      try {
          const response = await fetch(`http://localhost:3500/messages/updateStatus/${id}`, {
              method: 'PUT',
              headers:headers,
              body: JSON.stringify({ status: 'read' }),
          });

          const messageResponse = await response.json();
      
          if (response.ok) {
              const newNotification = {
                  message: messageResponse.message,
                  type: 'success'
              };
          
              setNotifications([newNotification, ...notifications]);
              setUserMessages(userMessages => userMessages.map(message => {
                  if (message._id === id) {
                      return { ...message, status: 'read' }; 
                  }
                  return message;
              }));
              setSelectedMessage('')
              setOpenMessage(false)

          } else {
              const newNotification = {
                  message: messageResponse.message,
                  type: 'error'
                };
          
                setNotifications([newNotification, ...notifications]);
              console.error('Error updating message status:', response.statusText);
          }

      } catch (error) {
          console.error('Error updating message status:', error);
      }
    }

  const handleGoToAllMessages = () => {
    setSearchMessageTerm('');
    setFilterMessageButton(false);
  }

  useEffect(() => {
    if (notifications.length > 1) {
        const timer = setTimeout(() => {
            removeNotification(-1); 
        }, 1000); 

        return () => {
            clearTimeout(timer); 
        };
    } else if (notifications.length === 1) {
        const timer = setTimeout(() => {
            removeNotification(-1); 
        }, 3000); 

        return () => {
            clearTimeout(timer); 
        };
    }
    // eslint-disable-next-line
  }, [notifications]);

  const handleGotoUserMessages = () => {
    setDeleteMessageButton(false);
  }

  const handleShowAllMessages = () => {
    setShowDeletedMessagesContainer(false);
    setDeleteMessageButton(false);
  }

  const handleMessageDeleteForever = async () => {
    console.log('delete forever is clicked')
  }

  return (
    <div>
      <div className='NotificationContainer'>
            {notifications.map((notification, index) => (
                <div key={index} className='Notification' style={{ bottom: `${index * 60}px` }} >
                    <div>{notification.type === 'success' ? (<SuccessIcon />) : (<ErrorIcon />)}</div>
                    <div className='NotificationText'>{notification.message}</div>
                    <button className='CloseButton' onClick={() => {removeNotification(index)}}> <CloseIcon /> </button>
                </div>
            ))}
        </div>

       <section className='profile-user'>
            <div>
            <Nav />
            </div>
        </section>

        <div className='page-content'>

          {/* <div className='message_page'>
            <div className='Message_notification_toggle'>
              { messageToggled ? (
                <div className='helpcenter_section'>
                  <div className='messages_title'>
                    <h2 className='support'>Help Center</h2>
                    <div className='ToggleInformation'>
                      <p style={{ opacity: 0.5, fontWeight:'600' }}>Notifications</p>
                      <div className={`circular_toggle_button ${messageToggled ? 'toggled' : ''}`} onClick={handleToggle}>
                        <div className='circle'></div>
                      </div>
                    </div>
                  </div>
                  
                  <div className='support_content'>
                    <section className='help_center_section'>
                      
                      <div className='contact_details'>
                      <h2 className='sub_heading'>Address</h2>
                        <p className='contact_info'><span className='weekday_hours'>Address</span> : 1911 West 15<sup>th</sup> Street, Plano TX</p>
                        <p className='contact_info'><span className='weekday_hours'>Tel</span> : +1 (972) 836-6770</p>
                        <p className='contact_info'><span className='weekday_hours'>Email</span> : miracleroofingandremodelling@gmail.com</p>
                      </div>

                      <div className='business_hours'>
                        <h2 className='sub_heading'>Customer Support Hours :</h2>
                        <p >Our customer support team is available to assist you during the following hours</p>
                        <p className='business_hours_info'><span className='weekday_hours'>Monday - Friday</span> : 8:00 AM - 6:00 PM</p>
                        <p className='business_hours_info'><span className='weekday_hours'>Saturday</span> : 9:00 AM - 3:00 PM</p>
                        <p className='business_hours_info'><span className='weekday_hours'>Sunday</span> : Closed</p>
                      </div>

                      <div className='emergency_support'>
                        <h2 className='sub_heading'>Emergency Support:</h2>
                        <p className='emergency_info'>For urgent matters requiring immediate attention outside of our regular business hours, please contact us via email at miracleroofingandremodelling@gmail.com or leave a voicemail at +1 (972) 836-6770. A member of our team will get back to you as soon as possible.</p>
                      </div>

                      <div className='faq_section'>
                        <h2 className='sub_heading'>Frequently Asked Questions</h2>
                        <div className='faq_item'>
                          <h4 className='faq_question'>1. What types of renovations do you specialize in?</h4>
                          <p className='faq_answer'>We specialize in kitchen remodels, bathroom renovations, home additions, and more. Explore our <Link className='link_servicepage' to='/dashboard' target='_blank' rel='noopener noreferrer'>services page</Link>{' '}for a complete list.</p>
                        </div>

                        <div className='faq_item'>
                          <h4 className='faq_question'>2. How long does a typical renovation project take?</h4>
                          <p className='faq_answer'>The duration varies based on the scope of the project. Our team will provide you with a timeline during the consultation phase.</p>
                        </div>

                        <div className='faq_item'>
                          <h4 className='faq_question'>3. Can I choose my own materials for the renovation?</h4>
                          <p className='faq_answer'>Certainly! We encourage collaboration, and you can choose your preferred materials. Our team can also provide recommendations based on your preferences.</p>
                        </div>
                        <div className='faq_item'>
                          <h4 className='faq_question'>4. Do you provide free estimates for renovation projects?</h4>
                          <p className='faq_answer'>Yes, we offer free estimates for all potential renovation projects. Simply contact us to schedule an appointment.</p>
                        </div>
                        
                        <div className='faq_item'>
                          <h4 className='faq_question'>5. What is your process for handling permits and regulatory approvals?</h4>
                          <p className='faq_answer'>Our team takes care of obtaining all necessary permits and approvals required for your renovation project, ensuring compliance with local regulations.</p>
                        </div>
                        
                        <div className='faq_item'>
                          <h4 className='faq_question'>6. Can you provide design assistance for my renovation project?</h4>
                          <p className='faq_answer'>Absolutely! We offer comprehensive design services to help bring your vision to life. Our team will work closely with you to create a customized plan tailored to your needs and preferences.</p>
                        </div>

                      </div>

                    </section>
                  </div>


                </div>

                ):(
                  <div className='notificationsection'>
                    <div className='messages_title'>
                      <h2>Notifications / Inbox</h2>
                      <div className='ToggleInformation'>
                        <p style={{ opacity: 0.5, fontWeight:'600' }}>Help Center</p>
                        <div className={`circular_toggle_button ${messageToggled ? 'toggled' : ''}`} onClick={handleToggle}>
                          <div className='circle'></div>
                        </div>
                      </div>
                    </div>
                    <div className='filter'>
                      <div className='Filter_button' onClick={handleiconfilter}>Filter Messages <Filter width={"12px"} height={"12px"} style={{marginLeft: '5px'}} /></div>
                        <div>
                        {filterstate && (
                          <div className='filterstypes'>
                            <select id="select" value={filteredoption} onChange={(e) => handleFilter(e.target.value)}>
                              <option>Select message type</option>
                              <option value="confirmation">Confirmation</option>
                              <option value="Invoice">Invoice</option>
                              <option value="notification">Notification</option>
                              <option value="update">Update</option>
                            </select>
                            <label>
                              <input type="checkbox" checked={unreadOnly} onChange={(e) => handleUnreadFilter(e.target.checked)} />
                              unread messages
                            </label>
                          </div>
                        )}
                        </div>
                    </div>

                    { filteredoption || unreadOnly ? (
                      <div className={`message-list ${selectedMessage ? 'hidden' : ''}`}>
                        {filteredmessage.sort((a, b) => {
                          const dateA = new Date(a.timestamp);
                          const dateB = new Date(b.timestamp);
                            return dateB - dateA;
                        }).map((message) => (
                          <div key={message._id} onClick={() => handleOpenMessage(message)}  className={`message_card ${message.status === 'unread' ? 'unread' : 'read'}`}>
                            <div className='message-header'>
                              <h2>{message.type}</h2>
                              <p>{new Date(message.timestamp).toLocaleString()}</p>
                            </div>
                              {message !== selectedMessage && (
                                <div className='message-summary'>
                                  {message.type === 'notification' && (
                                    <p>{message.message.substring(0, 50)} ...</p>
                                  )}
                                  {message.type === 'Invoice' && (
                                    <p>{message.message.substring(26, 81)} ...</p>
                                  )}
                                  {message.type === 'confirmation' && (
                                    <p>{message.message.substring(0, 50)} ...</p>
                                  )}
                                  {message.type === 'update' && (
                                    <p>{message.message.substring(0, 43)} ...</p>
                                  )}
                                </div>
                              )}
                          </div>
                        ))}
                      </div>
                    ):(
                      <div className={`message-list ${selectedMessage ? 'hidden' : ''}`}>
                        {messages.sort((a, b) => {
                          const dateA = new Date(a.timestamp);
                          const dateB = new Date(b.timestamp);
                            return dateB - dateA;
                        }).map((message) => (
                          <div key={message._id} onClick={() => handleOpenMessage(message)}  className={`message_card ${message.status === 'unread' ? 'unread' : 'read'}`}>
                            <div className='message-header'>
                              <h2>{message.type}</h2>
                              <p>{new Date(message.timestamp).toLocaleString()}</p>
                            </div>
                              {message !== selectedMessage && (
                                <div className='message-summary'>
                                  {message.type === 'notification' && (
                                    <p>{message.message.substring(0, 50)} ...</p>
                                  )}
                                  {message.type === 'Invoice' && (
                                    <p>{message.message.substring(26, 81)} ...</p>
                                  )}
                                  {message.type === 'confirmation' && (
                                    <p>{message.message.substring(0, 50)} ...</p>
                                  )}
                                  {message.type === 'update' && (
                                    <p>{message.message.substring(0, 43)} ...</p>
                                  )}
                                </div>
                              )}
                          </div>
                        ))}
                      </div>
                    )}

                    { selectedMessage && (
                      <div className='message-popup'>
                        <section className='message_content'>
                          <div className='content_header'>
                            <h2>{selectedMessage.type}</h2>
                            <div className='content_side_buttons'>
                              <button className='close-message' onClick={handleCloseMessage}>Close</button>
                              {(selectedMessage.type === 'Invoice') && (
                                <>
                                  <button className='download-message' onClick={handleDownload}>Download</button>
                                  <button className='show_message' onClick={fetchInvoice}>Show Invoice</button>
                                </>
                              )}
                              {(selectedMessage.type === 'confirmation' || selectedMessage.type === 'notification' || selectedMessage.type === 'update') && (
                                <button className='delete-message' onClick={handleDelete}>Delete</button>
                              )}
                            </div>
                          </div>
                          <div className='message-content-scrollable'>
                            <p dangerouslySetInnerHTML={{ __html: selectedMessage.message }} />
                            <div>
                              {invoice ? (
                                <div>
                                  <div className="invoice-container">
                                    <div className="invoice-header">
                                      <img src={logo} alt="Company Logo" className="company-logo" />
                                      <div className="company-info">
                                        <h1>{invoice.companyName}</h1>
                                        <p>{invoice.companyAddress}</p>
                                        <p>{invoice.companyCity}, {invoice.companyState} {invoice.companyZipCode}</p>
                                        <p>Phone: {invoice.companyPhone}</p>
                                        <p>Email: {invoice.companyEmail}</p>
                                        <p>Website: {invoice.companyWebsite}</p>
                                      </div>
                                    </div>
                              
                                    <div className="invoice-details">
                                      <div className="invoice-info">
                                        <h2>Invoice:</h2>
                                        <p>Invoice Number: {invoice.invoiceNumber}</p>
                                        <p>Invoice Date: {invoice.invoiceDate.split('T')[0]}</p>
                                        <p>Due Date: {invoice.dueDate.split('T')[0]}</p>
                                      </div>
                              
                                      <div className="billing-info">
                                        <h2>Bill To:</h2>
                                        <p>{invoice.customerName}</p>
                                        <p>{invoice.customerAddress}</p>
                                        <p>{invoice.customerCity}, {invoice.customerState} {invoice.customerZipCode}</p>
                                        <p>Phone: {invoice.customerPhone}</p>
                                        <p>Email: {invoice.customerEmail}</p>
                                      </div>
                              
                                      {invoice.shippingAddress && (
                                        <div className="shipping-info">
                                          <h2>Ship To:</h2>
                                          <p>{invoice.shippingAddress}</p>
                                        </div>
                                      )}
                                    </div>
                              
                                    <table className="invoice-table">
                                      <thead>
                                        <tr>
                                          <th>Item #</th>
                                          <th>Description</th>
                                          <th>Quantity</th>
                                          <th>Unit Price</th>
                                          <th>Total</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {invoice.items.map((item, index) => (
                                          <tr key={index}>
                                            <td>{item[`itemNumber_${index}`]}</td>
                                            <td>{item[`description_${index}`]}</td>
                                            <td>{item[`quantity_${index}`]}</td>
                                            <td>{item[`unitPrice_${index}`]}</td>
                                            <td>{item[`total_${index}`]}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                              
                                    <div className="invoice-summary">
                                      <div className="invoice-totals">
                                        <p>Subtotal: {invoice.subtotal}</p>
                                        <p>Shipping: {invoice.shipping}</p>
                                        <p>Tax ({invoice.taxPercentage}%): {invoice.taxAmount}</p>
                                        <p>Total: {invoice.total}</p>
                                      </div>
                                    </div>
                              
                                    <div className="invoice-payment">
                                      <h2>Payment Information:</h2>
                                      <p>{invoice.paymentMethods}</p>
                                      <p>{invoice.paymentInstructions}</p>
                                    </div>
                              
                                    <div className="invoice-notes">
                                      <h2>Notes:</h2>
                                      <p>{invoice.notes}</p>
                                    </div>
                              
                                    <div className="invoice-thank-you">
                                      <p>Thank you for your business!</p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </section>
                      </div>
                    )}
                  </div>
                  
              )}
            </div>
          </div> */}
          <div>
                { filterMessageButton && openMessage === false && showDeletedMessagesContainer === false && filteredMessages.length !== 0 ? (
                    <div className='inboxMainContainer'>
                        <div className='topMessageContainer'>
                            <div className='topButtonContainerMessage'>
                                <button className='CloseButton' onClick={handleGoToAllMessages} ><BackIcon /></button>
                                <div className='topButtonContainerMessage'>
                                    {/* <button className='newMessagebutton' onClick={() => setNewMessageContainer(true)}>New Message</button> */}
                                    <button className='Trashbutton' onClick={handleShowDeletedMessages}> <TrashIcon /> Trash</button>
                                </div>
                            </div>
                            <div className='topPaginationContainer'>
                                <div className='topBar'>
                                    <input type="text" className='searchBar' value={searchMessageTerm} placeholder="Search Message" onChange={(e) => {setSearchMessageTerm(e.target.value)}} />
                                    <button className='searchIcon' onClick={handleMessageSearch} ><SearchIcon /> </button>
                                    <div className='messageInfo'>Showing {indexOfFirstMessage + 1} - {Math.min(indexOfLastMessage, filteredMessages.length)} of {filteredCurrentMessages.length} messages</div>
                                </div>
                                <div className='pagination'>
                                    <button className='paginationButton' onClick={goToPreviousPage} disabled={currentPage === 0}><PrevIcon /></button>
                                    <button className='paginationButton' onClick={goToNextPage} disabled={indexOfLastMessage >= filteredMessages.length}><NextIcon /></button>
                                </div>
                            </div>
                        </div>
                        <div className='previousMessageContainer'>
                            {filteredMessages.map((message, index) => (
                            <div key={index} className='messageListItem' onClick={() => handleMessageClick(message, 'filteredMessages')}>
                                <p><strong>{message.type}</strong></p>
                                <p>{message.subject}</p>
                                <p>{new Date(message.timestamp).toLocaleString()}</p>
                            </div>
                            ))}
                        </div>
                    </div>
                ) : messageOption === 'filteredMessage' && filterMessageButton && showDeletedMessagesContainer === false && openMessage === true && filteredMessages.length !== 0 ?  (
                    <div className='inboxMainContainer'>
                        <div className='openMessageContainer'>
                            <div className='leftSideOpenMessage'>
                                <button className='CloseButton' onClick={() => setOpenMessage(false)}><BackIcon /></button>
                                <div className='rightInLeftSideOpenMessage'>
                                    {selectedMessage && selectedMessage.status === 'unread' ? (
                                        <>
                                            <button className='IconButton' title='Read' onClick={() => handleMessageRead(selectedMessage._id)}><MessageReadIcon /></button>
                                            <button className='IconButton' title='Trash' onClick={() => handleMessageDelete(selectedMessage._id)}><TrashIcon /></button>
                                        </>
                                    ) : (
                                        <>
                                            <button className='IconButton' title='Unread' onClick={() => handleMessageUnRead(selectedMessage._id)}><MessageUnreadIcon /></button>
                                            <button className='IconButton' title='Trash' onClick={() => handleMessageDelete(selectedMessage._id)}><TrashIcon /></button>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className='topPaginationContainer'>
                                <div className='topBar'>
                                    <div className='messageInfo'>{selectedMessageIndex} of {filteredMessages.length}</div>
                                </div>
                                <div className='pagination'>
                                    <button className='paginationButton' onClick={goToPrevFilteredMessage} disabled={selectedMessageIndex === 1}><PrevIcon /></button>
                                    <button className='paginationButton' onClick={goToNextFilteredMessage} disabled={selectedMessageIndex >= filteredMessages.length}><NextIcon /></button>
                                </div>
                            </div>
                        </div>
                        <div className='openMessageContent'>
                            {selectedMessage && (
                                <span className='message_content' dangerouslySetInnerHTML={{ __html: selectedMessage.message }} />
                            )}
                        </div>

                    </div>
                ) : null}

                { !filterMessageButton && openMessage === false && showDeletedMessagesContainer === false && currentMessages.length !== 0 ? (
                    <div className='inboxMainContainer'>
                        <div className='topMessageContainer'>
                            <div className='topButtonContainerMessage'>
                                <div className='topButtonContainerMessage'>
                                    {/* <button className='newMessagebutton' onClick={() => setNewMessageContainer(true)}>New Message</button> */}
                                    <button className='Trashbutton' onClick={handleShowDeletedMessages} >Trash</button>
                                </div>
                            </div>
                            <div className='topPaginationContainer'>
                                <div className='topBar'>
                                    <input type="text" className='searchBar' value={searchMessageTerm} placeholder="Search Message" onChange={(e) => {setSearchMessageTerm(e.target.value)}} />
                                    <button className='searchIcon' onClick={handleMessageSearch} ><SearchIcon /> </button>
                                    <div className='messageInfo'>Showing {indexOfFirstMessage + 1} - {Math.min(indexOfLastMessage, currentMessages.length)} of {userMessages.length} messages</div>
                                </div>
                                <div className='pagination'>
                                    <button className='paginationButton' onClick={goToPreviousPage} disabled={currentPage === 0}><PrevIcon /></button>
                                    <button className='paginationButton' onClick={goToNextPage} disabled={indexOfLastMessage >= userMessages.length}><NextIcon /></button>
                                </div>
                            </div>
                        </div>
                        <div className='previousMessageContainer'>
                            {currentMessages.map((message, index) => (
                            <div key={index} className={`messageListItem ${message.status === 'read' ? 'unread_messages' : 'read_messages'}`} onClick={() => handleMessageClick(message,'userMessages')}>
                                <p><strong>{message.type}</strong></p>
                                <p>{message.subject}</p>
                                <p>{new Date(message.timestamp).toLocaleString()}</p>
                            </div>
                            ))}
                        </div>
                    </div>

                ) :  messageOption === 'userMessage' && !filterMessageButton && showDeletedMessagesContainer === false && openMessage === true && currentMessages.length !== 0 ?  (
                    <div className='inboxMainContainer'>
                        <div className='openMessageContainer'>
                            <div className='leftSideOpenMessage'>
                                <button className='CloseButton' onClick={() => setOpenMessage(false)}><BackIcon /></button>
                                <div className='rightInLeftSideOpenMessage'>
                                    {selectedMessage && selectedMessage.status === 'unread' ? (
                                        <>
                                            <button className='IconButton' title='Read' onClick={() => handleMessageRead(selectedMessage._id)}><MessageReadIcon /></button>
                                            <button className='IconButton' title='Trash' onClick={() => handleMessageDelete(selectedMessage._id)}><TrashIcon /></button>
                                        </>
                                    ) : (
                                        <>
                                            <button className='IconButton' title='Unread' onClick={() => handleMessageUnRead(selectedMessage._id)}><MessageUnreadIcon /></button>
                                            <button className='IconButton' title='Trash' onClick={() => handleMessageDelete(selectedMessage._id)}><TrashIcon /></button>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className='topPaginationContainer'>
                                <div className='topBar'>
                                    <div className='messageInfo'>{selectedMessageIndex} of {currentMessages.length}</div>
                                </div>
                                <div className='pagination'>
                                    <button className='paginationButton' onClick={goToPrevCurrentMessage} disabled={selectedMessageIndex === 1}><PrevIcon /></button>
                                    <button className='paginationButton' onClick={goToNextCurrentMessage} disabled={selectedMessageIndex >= currentMessages.length}><NextIcon /></button>
                                </div>
                            </div>
                        </div>
                        <div className='openMessageContent'>
                            {selectedMessage && (
                                <span className='message_content' dangerouslySetInnerHTML={{ __html: selectedMessage.message }} />
                            )}
                        </div>

                    </div>

                ) : null}

                { !filterMessageButton && deleteMessageButton && openMessage === false && showDeletedMessagesContainer === true && deletedMessagesList.length !== 0 ? (
                    <div className='inboxMainContainer'>
                        <div className='topMessageContainer'>
                            <div className='topButtonContainerMessage'>
                                <button className='CloseButton' onClick={handleGotoUserMessages} ><BackIcon /></button>
                                <div className='rightTopButtonContainerMessage'>
                                    {/* <button className='newMessagebutton' onClick={() => setNewMessageContainer(true)}>New Message</button> */}
                                    <button className='Trashbutton' onClick={handleShowAllMessages} >All Message</button>
                                </div>
                            </div>
                            <div className='topPaginationContainer'>
                                <div className='topBar'>
                                    {/* <input type="text" className='searchBar' value={searchMessageTerm} placeholder="Search Message" onChange={(e) => {setSearchMessageTerm(e.target.value)}} /> */}
                                    {/* <button className='searchIcon' onClick={handleDeletedMessageSearch} ><SearchIcon /> </button> */}
                                    <div className='messageInfo'>Showing {indexOfFirstMessage + 1} - {Math.min(indexOfLastMessage, deletedCurrentMessages.length)} of {deletedMessagesList.length} messages</div>
                                </div>
                                <div className='pagination'>
                                    <button className='paginationButton' onClick={goToPreviousPage} disabled={currentPage === 0}><PrevIcon /></button>
                                    <button className='paginationButton' onClick={goToNextPage} disabled={indexOfLastMessage >= deletedMessagesList.length}><NextIcon /></button>
                                </div>
                            </div>
                        </div>
                        <div className='previousMessageContainer'>
                            {deletedMessagesList.map((message, index) => (
                            <div key={index} className='messageListItem' onClick={() => handleMessageClick(message, 'deletedMessagesList')}>
                                <p><strong>{message.type}</strong></p>
                                <p>{message.subject}</p>
                                <p>{new Date(message.timestamp).toLocaleString()}</p>
                            </div>
                            ))}
                        </div>
                    </div>
                ) : messageOption === 'deletedMessage' && showDeletedMessagesContainer === true && !filterMessageButton && deleteMessageButton && openMessage === true && deletedMessagesList.length !== 0 ? (
                    <div className='inboxMainContainer'>
                        <div className='openMessageContainer'>
                            <div className='leftSideOpenMessage'>
                                <button className='CloseButton' onClick={() => setOpenMessage(false)}><BackIcon /></button>
                                <div className='rightInLeftSideOpenMessage'>
                                    { selectedMessage && selectedMessage.status === 'deleted' ? (
                                        <>
                                            <button className='CloseButton' title='Unread' onClick={() => handleMessageDeleteForever(selectedMessage._id)}>Delete Forever</button>
                                            <button className='IconButton' title='Trash' onClick={() => handleMessageDelete(selectedMessage._id)}><TrashIcon /></button>
                                        </>
                                    ) : (null)}
                                   
                                </div>
                            </div>
                            <div className='topPaginationContainer'>
                                <div className='topBar'>
                                    <div className='messageInfo'>{selectedMessageIndex} of {deletedMessagesList.length}</div>
                                </div>
                                <div className='pagination'>
                                    <button className='paginationButton' onClick={goToPrevDeletedMessage} disabled={selectedMessageIndex === 1}><PrevIcon /></button>
                                    <button className='paginationButton' onClick={goToNextDeletedMessage} disabled={selectedMessageIndex >= deletedCurrentMessages.length}><NextIcon /></button>
                                </div>
                            </div>
                        </div>
                        <div className='openMessageContent'>
                            {selectedMessage && (
                                <span className='message_content' dangerouslySetInnerHTML={{ __html: selectedMessage.message }} />
                            )}
                        </div>

                    </div>
                ) : null}
          </div>
        </div>
    </div>
    
  )
}

export default Message