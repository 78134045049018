import React from 'react'
// import './Footer.css'

// import logo from './images/logo-f7f7f7.png'
// import logo from './images/newlogo-f7f7f7.png'
import { ReactComponent as Youtube } from './images/youtube.svg'
import { ReactComponent as Github } from './images/github.svg'
import { ReactComponent as Linkedin } from './images/linkedin-in.svg'
import { ReactComponent as Twitter } from './images/twitter.svg'
import { Link } from 'react-router-dom'

function Footer({loggedIn}) {

  return (

    <>
      {loggedIn ? null : (
        // <footer className="footer-container">
        //   <div className="footer-content">
        //     <div className="footer-column">
        //       <h3 className="footer-heading">Company</h3>
        //       <ul className="footer-links">
        //         <li><Link to="/company/about" className="footer-link">About</Link></li>
        //         <li><Link to="Solar-roof" className="footer-link">Solar Roof</Link></li>
        //         <li><Link to="Renovation" className="footer-link">Renovation</Link></li>
        //         <li><Link to="/contact-us" className="footer-link">Contact Us</Link></li>
        //       </ul>
        //     </div>
        //     <div className="footer-column">
        //       <h3 className="footer-heading">Legal</h3>
        //       <ul className="footer-links">
        //         <li><Link to="/legal/terms" className="footer-link">Terms of Service</Link></li>
        //         <li><Link to="/legal/privacy" className="footer-link">Privacy Policy</Link></li>
        //         <li><Link to="/legal/cookie" className="footer-link">Cookie Policy</Link></li>
        //       </ul>
        //     </div>
        //     {/* <div className="footer-column">
        //       <h3 className="footer-heading">Social</h3>
        //       <ul className="footer-social-links">
        //         <li><Link to="#" className="link-footer-twitter footer-social-link"><Twitter /> Twitter</Link></li>
        //         <li><Link to="#" className="link-footer-linkedin footer-social-link"><Linkedin /> LinkedIn</Link></li>
        //         <li><Link to="#" className="link-footer-github footer-social-link"><Github /> GitHub</Link></li>
        //         <li><Link to="#" className="link-footer-youtube footer-social-link"><Youtube /> YouTube</Link></li>
        //       </ul>
        //     </div> */}
        //     <div className="footer-column">
        //       <div className="footer-contact">
        //         <p>Phone: (972) 836-6770</p>
        //         <p>Email: info@miracleroofing.com</p>
        //         <p className='head2'>9111 West 15<sup>th</sup> Street<br/>Plano, TX 75075</p>
        //         {/* {address && <p>Address: {address}</p>} */}
        //       </div>
        //     </div>
        //   </div>
          // <div className="footer-legal"> 
          //   <p>&copy; 2024 MiracleRoofing Inc. All rights reserved.</p>
          //   <div className="footer-social-down">
          //       <div><Link to="#" className="link-footer-twitter footer-social-downtag"><Twitter /></Link></div>
          //       <div><Link to="#" className="link-footer-linkedin footer-social-downtag"><Linkedin /></Link></div>
          //       <div><Link to="#" className="link-footer-github footer-social-downtag"><Github /></Link></div>
          //       <div><Link to="https://www.youtube.com/" className="link-footer-youtube footer-social-downtag"><Youtube /></Link></div>
          //   </div>
          // </div>
        // </footer>
        <footer className="footer">
        <div className="footer_container">
            <div className="footerrow">
                <div className="footer-col">
                    <h4>Miracle Roofing</h4>
                    <ul className='footer_ul'>
                      <li><Link to="/company/about" className="footer-link">About</Link></li>
                      <li><Link to="Solar-roof" className="footer-link">Solar Roof</Link></li>
                      <li><Link to="Renovation" className="footer-link">Renovation</Link></li>
                      <li><Link to="/Service" className="footer-link">Services</Link></li>
                    </ul>
                </div>
                <div className="footer-col">
                    <h4>Get help</h4>
                    <ul className='footer_ul'>
                      <li><Link to="/contact-us" className="footer-link">Contact Us</Link></li>
                      <li><p className='footer-column'>Phone: (972) 836-6770</p>
                      <p className='footer-column'>Email: info@miracleroofing.com</p>
                      <p className='head2 footer-column'>9111 West 15<sup>th</sup> Street<br/>Plano, TX 75075</p></li>
                    </ul>
                </div>
                <div className="footer-col">
                    <h4>Follow us</h4>
                    <div className="social-links">
                      <ul className='footer_ul footer_line'>
                        <li><Link to="#" className="link-footer-twitter footer-social-link"><Twitter /></Link></li>
                        <li><Link to="#" className="link-footer-linkedin footer-social-link"><Linkedin /></Link></li>
                        <li><Link to="#" className="link-footer-github footer-social-link"><Github /></Link></li>
                        <li><Link to="#" className="link-footer-youtube footer-social-link"><Youtube /></Link></li>
                      </ul>
                    </div>
                </div>
                {/* <div class="footer-col">
                  <div className="footer-column">
                    <div className="footer-contact">
                    <p>Phone: (972) 836-6770</p>
                    <p>Email: info@miracleroofing.com</p>
                    <p className='head2'>9111 West 15<sup>th</sup> Street<br/>Plano, TX 75075</p>
                    </div>
                  </div>
                </div> */}
            </div>
        </div>   
        <div className="footer-legal"> 
                <p>&copy; 2024 MiracleRoofingandRemodelling Inc. All rights reserved.</p>
              </div>
    </footer>
      )}
    </>
  )
}

export default Footer

