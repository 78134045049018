import React, { useState, useEffect } from 'react';

const Typewriter = ({ message, time, loop, newcard, onTypingEnd }) => {
  const [currentMessage, setCurrentMessage] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const typingInterval = setInterval(() => {

      if (currentIndex < message.length) {
        setCurrentMessage((prevMessage) => prevMessage + message[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      } else if (loop) {
          setCurrentMessage('');
          setCurrentIndex(0);
      } else {
        if (onTypingEnd) {
          onTypingEnd();
        }
      }
      
    }, time);

    return () => {
      clearInterval(typingInterval);
    };
  }, [currentIndex, loop, message, time, onTypingEnd]);

  useEffect(() => {
    if (newcard) {
      setCurrentMessage('');
      setCurrentIndex(0);
    }
  }, [newcard]);

  return <>{currentMessage}</>;
};

export default Typewriter;
