import React, { useEffect, useState, useRef } from 'react'
import './Dashboard.css'
import { ReactComponent as DeleteIcon } from '../../../component/images/trash.svg';
import { ReactComponent as EditIcon } from '../../../component/images/pencil-solid.svg';
import { ReactComponent as Starfull } from '../../../component/images/star-solid.svg';
import { ReactComponent as ArrowIconRight } from '../../../component/images/angle-down-solid.svg';

import BookAppointment from './BookAppointment'
import Nav from '../Nav/Nav'

function Dashboard() {
  const [userAppointments, setUserAppointments] = useState(window.localStorage.getItem("upcomingAppointments"));
  const [pastappointments, setPastappointments] = useState(window.localStorage.getItem("oldAppointments"));
  const [showAppointment, setShowAppointment] = useState(false)
  const [editOn, setEditOn] = useState(false)
  const [editappointmentId, setEditappointmentId] = useState('')
  const [name, setName] = useState(window.localStorage.getItem('firstName') || 'Guest');
  const [useraccountid, setUseraccountid] = useState(window.localStorage.getItem("accountid"))
  const [appservice,setAppservice] = useState('')
  const [apptime,setApptime] = useState('')
  const [appreview, setAppreview] = useState({})
  const [appdate,setAppdate] = useState('')
  const [userrating, setUserrating] = useState({});
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [availableDates, setAvailableDates] = useState([]);
  const [availableServices, setAvailableServices] = useState([]);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [address, setAddress] = useState('');
  const [service, setService] = useState('');
  const [services, setServices] = useState([]);
  const [visiblePastAppointments, setVisiblePastAppointments] = useState(4);
  const [visibleComingAppointments, setVisibleComingAppointments] = useState(4);
  const targetSectionRef = useRef(null);


  const token = window.localStorage.getItem("token")
  const headers =  {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization:token,
    "Access-Control-Allow-Origin": "*",
  }

  useEffect(() => {
    fetchData();
    // fetchUserAppointments();
    fetchServices();
    // eslint-disable-next-line
  }, []);

  const fetchUserAppointments = async () => {
    try {
      const response = await fetch("http://localhost:3500/userAppointments", {
        method: "POST",
        crossDomain: true,
        headers:headers,
        body: JSON.stringify({
          // userEmail: email,
          accountuser:useraccountid,
        }),
      });

      const data = await response.json();
      // console.log(data)
      if (data.status === 'ok') {
        setUserAppointments(data.upcomingAppointments);
        setPastappointments(data.oldAppointments);  
      }
    } catch (error) {
      console.log('Error fetching user appointments:', error);
    }
  };
  
  useEffect(() => {
      fetchUserAppointments();
      // eslint-disable-next-line
  },[useraccountid]);

  const fetchData = async () => {
    try {
      const response = await fetch("http://localhost:3500/userData", {
        method: "POST",
        crossDomain: true,
        headers:headers,
        body: JSON.stringify({
          token: window.localStorage.getItem("token"),
        }),
      });

      const data = await response.json();
      if (data.status === 'ok') {
        setName(data.data.firstName);
        setUseraccountid(data.data.accountid);
      }
    } catch (error) {
      console.log('Error fetching user data:', error);
    }
  };



  const fetchServices = async () => {
      try {
        const response = await fetch(`http://localhost:3500/allservices`, {
          method: 'GET',
          headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "Access-Control-Allow-Origin": "*",
          },
        });
  
        const data = await response.json();
        if (data.status === 'ok') {
          setServices(data.data);
        }
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    };


  const deleteAppointment = async (appointmentId) => {

    const appointmentdelete = {
      address: [],
      service: "None",
      accountid: '0',
    };
    const appointmentdetails = {
      date: appointmentId.date,
      service: appointmentId.service,
      time: appointmentId.time,
      accountid:useraccountid
    };
    console.log(appointmentdelete,appointmentdetails)

    try {
      const response = await fetch(`http://localhost:3500/appointments/${appointmentId._id}`, {
        method: 'PUT',
        headers:headers,

        body: JSON.stringify({appointmentdelete, appointmentdetails}),
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to delete appointment');
      }
      const data = await response.json();
      console.log(data.message);
      
      // window.location.reload();
      fetchUserAppointments();
      console.log('delete',appointmentdelete,'details',appointmentdetails)
    } catch (error) {
      console.error('Error deleting appointment:', error.message);
      // Handle error and show error message to the user
    }
  };


  const handledelete = (appointmentId) => {
    console.log(`Delete for ${appointmentId._id} is selected`);
    alert('The appointment will be deleted')
    deleteAppointment(appointmentId);
  };

  const handleedit = (appointmentId) => {
    setEditOn(true)
    setEditappointmentId(appointmentId)
    const selectedAppointment = userAppointments.find((appointment) => appointment._id === appointmentId);
    setAppdate(selectedAppointment.date)
    setAppservice(selectedAppointment.service)
    setApptime(selectedAppointment.time)
    setAddress(selectedAppointment.address)
    fetchAvailableDates();
    fetchAvailableServices();
    console.log(`edit for ${appointmentId} is selected`)
  }

  const handleeditcancel = () => {
    setEditOn(false)
    setEditappointmentId('');
    setDate('');
    setTime('');
    setService('');

  };

  const handleeditsubmit = async () => {
    setEditOn(false);

    const appointmenteditold = {
      time: apptime,
      service: appservice,
      date: appdate,
      accountid:useraccountid,
      address:address ,
    };
    const appointmenteditnew = {
      date: date,
      service: service,
      time: time,
    };
    // console.log(appointmenteditold);
    // console.log(appointmenteditnew);
    if (appointmenteditnew.date && appointmenteditnew.service && appointmenteditnew.time) {
      try {
        // Call the backend API to update the appointment
        const response = await fetch(`http://localhost:3500/appointments1/${editappointmentId}`, {
          method: 'PUT',
          headers:headers,

          body: JSON.stringify({ old: appointmenteditold, new: appointmenteditnew }),
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Failed to update appointment');
        }

        const data = await response.json();
        console.log(data.message);
        const updatedAppointments = userAppointments.map((appointment) =>
          appointment._id === editappointmentId ? { ...appointment, ...appointmenteditnew } : appointment
        );

        setUserAppointments(updatedAppointments);

      } catch (error) {
        console.error('Error updating appointment:', error.message);
      }
    }
  };

  const handleRatingClick = async (appointmentId, rating) => {
  
    try {
      // Call the backend API to update the rating
      const response = await fetch(`http://localhost:3500/appointments1/${appointmentId}/rating`, {
        method: 'PUT',
        headers:headers,
        body: JSON.stringify({ rating }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to update rating');
      }
  
      const data = await response.json();
      console.log(data.message);
  
      // Update user ratings
      setUserrating((prevRatings) => ({
        ...prevRatings,
        [appointmentId]: rating,
      }));

      fetchUserAppointments();
  
    } catch (error) {
      console.error('Error updating rating:', error.message);
    }
  };

  
  
  const takereviewforappointment = (appointmentId) => {
    setSelectedAppointment(appointmentId);
  }


  const sendreview = async (appointmentId, review) => {
    if (review === '' || review === 'Default') {
      return;
    }
    try {
      // Call the backend API to update the rating
      const response = await fetch(`http://localhost:3500/appointments1/${appointmentId}/review`, {
        method: 'PUT',
        headers:headers,
        body: JSON.stringify({ review }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to update review');
      }
  
      const data = await response.json();
      console.log(data.message);
  
      // Update user ratings
      setAppreview((prevRatings) => ({
        ...prevRatings,
        [appointmentId]: review,
      }));

      setSelectedAppointment(null);
      fetchUserAppointments();
  
    } catch (error) {
      console.error('Error updating review:', error.message);
    }
  };
  const closePopup = () => {
    // Remove the 'active' class from the popup container
    setSelectedAppointment(null); // Assuming setSelectedAppointment sets it to null
  };

  const fetchAvailableDates = async () => {
    try {
      const response = await fetch('http://localhost:3500/available-dates',{headers:headers});
      const dates = await response.json();
      setAvailableDates(dates);
    } catch (error) {
      console.error('Error fetching available dates:', error);
    }
  };
  
  const fetchAvailableServices = async () => {
    try {
      const response = await fetch('http://localhost:3500/available-services',{headers:headers});
      const services = await response.json();
      setAvailableServices(services);
    } catch (error) {
      console.error('Error fetching available services', error);
    }
  };

  const handleDateChange = async (selectedDate) => {
    try {
      setDate(selectedDate)
      const response = await fetch(`http://localhost:3500/available-slots/${selectedDate}`,{headers:headers,}); // Replace with your endpoint
      const data = await response.json();
      const slots = data.map(slot => slot.time);
      setAvailableSlots(slots);
    } catch (error) {
      console.error('Error fetching available slots:', error);
    }
  };

  const showMorePastAppointments = () => {
    setVisiblePastAppointments(prevpastAppointments => prevpastAppointments + 4);
  };

  const showMoreUpcomingAppointments = () => {
    setVisibleComingAppointments(prevcomingappointment => prevcomingappointment + 4);
  }

  const handleBookAppoint = () => {
    setShowAppointment(prevstate => !prevstate)
    console.log('showappointment', showAppointment)
    if(showAppointment === false) {
      targetSectionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start'});
    }
  }
  


  return (
    <div>
      <section className='profile-user'>
          <div>
            <Nav />
          </div>
      </section>

      <div className='page-content'>
          <div className='user-details'>
              <div className='user-info'>
                  <h2>Welcome, {name}!</h2>
                  <h3>Upcoming Appointments</h3>
                  {userAppointments ? ( userAppointments.length > 0 ? (
                    <div className='appointment-cards-container'>
                      {userAppointments.slice(0, visibleComingAppointments).map((appointment) => (
                        <div key={appointment._id} className='appointment-card'>
                            <div className='card-header'>
                              <h3>Appointment</h3>
                              <div className='card-icons'>
                                <EditIcon className='edit-icon' onClick={() => handleedit(appointment._id)}/>
                                <DeleteIcon className='delete-icon' onClick={() => handledelete(appointment)} />
                              </div>
                            </div>
                            {editOn && editappointmentId === appointment._id ? (
                            <div>
                              <form>
                                <div className='Edit-appointment-container'>
                                  <select value={date} onChange={(e) => handleDateChange(e.target.value)} required>
                                    <option value=''>Select a date</option>
                                    {availableDates.map((date) => (
                                      <option key={date} value={date}>
                                        {date}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div className='Edit-appointment-container'>
                                  <select value={time} onChange={(e) => setTime(e.target.value)} required>
                                    <option value=''>Select a time slot</option>
                                    {availableSlots.map((slot) => (
                                      <option key={slot} value={slot}> {slot} </option>
                                    ))}
                                  </select>
                                </div>
                                <div className='Edit-appointment-container'>
                                  <select value={service} onChange={(e) => setService(e.target.value)} required>
                                    <option value=''>Select a Service</option>
                                      {availableServices.map((services) => (
                                        <option key={services} value={services}> {services} </option>
                                      ))}
                                  </select>
                                </div>
                              </form>
                              <div className='edit-button-div'>
                                <button className='Button-edit-appointment' onClick={handleeditsubmit}>Save</button>
                                <button className='Button-edit-appointment' onClick={handleeditcancel}>Cancel</button>
                              </div>
                            </div>
                              ):(
                            <div>
                              <p>
                              <strong>Date:</strong> {appointment.date.split('T')[0]}
                              </p>
                              <p>
                              <strong>Time:</strong> {appointment.time}
                              </p>
                              <p>
                              <strong>Service:</strong> {appointment.service}
                              </p>
                            </div>
                            )}
                        </div>
                      ))}
                  </div>
                  ) : (
                  <p>No future appointments scheduled.</p>
                  )) : (
                    <div>
                      <p>Loading ...</p>
                    </div>
                  )}

                  {userAppointments && userAppointments.length > visibleComingAppointments && (
                    <div className='seemore_container'>
                      <button className='seemore' onClick={showMoreUpcomingAppointments}>Load More <div className='seemore_arrow'><ArrowIconRight/></div></button>
                    </div>
                  )}

              </div>
        </div>

        <div className='button-appointment' ref={targetSectionRef}>
            <button className='button-display-appointment' onClick={handleBookAppoint}>{showAppointment ? 'Cancel Appointment':'Book an Appointment'}</button>
        </div>

        <section className='book-appoint-section'>
            { showAppointment && <BookAppointment useraccount={useraccountid} /> }
        </section>

        <div>
          <div className='user-details'>
            <div className='user-info'>
              <h3>Past Appointments</h3>
              {pastappointments ? ( pastappointments.length > 0 ? (
                <div className='appointment-cards-container'>
                  {pastappointments.slice(0, visiblePastAppointments).map((appointment) => (
                    <div key={appointment._id} className='appointment-card'>
                      <div className='card-header'>
                        <div className={`${appointment.status === 'Completed' ? 'completed-text' : (appointment.status === 'Cancelled' ? 'cancelled-text' : 'active-text')}`}>{appointment.status}</div>
                        <div className='card-icons'>
                          {appointment.rating ? (
                            <div className='userrating-icon'>
                              {[1, 2, 3, 4, 5].map((index) => (
                                <Starfull
                                  key={index}
                                  fill={index <= appointment.rating ? 'gold' : '#e1e1e1'}
                                  size={30}
                                />
                              ))}
                            </div>
                          ) : ( 
                            appointment.review === 'Default' ? (
                              <div>
                                {appointment.status !== 'Cancelled' && appointment.status !== 'Open' && (
                                  <button className='review-appointment' onClick={() => takereviewforappointment(appointment._id)}>Review</button>
                                )}
                                {selectedAppointment === appointment._id && (
                                <div className='popup-container active'>
                                  <div className='popup-content'>
                                    <form>
                                      <textarea className='review-input-field' value={appreview[appointment._id] || ''} onChange={(e) => setAppreview((prevReviews) => ({...prevReviews, [appointment._id]: e.target.value,}))}></textarea>
                                    </form>
                                    <button className='review-appointment' type='submit' onClick={() => sendreview(appointment._id, appreview[appointment._id])}>Submit</button>
                                    <span className='review-appointment' onClick={() => closePopup()}>Cancel</span>
                                  </div>
                                </div>
                                )}
                              </div>
                            ) : (
                              <div className='userrating-icon'>
                                {[1, 2, 3, 4, 5].map((index) => (
                                  <Starfull
                                    key={index}
                                    onClick={() => handleRatingClick(appointment._id, index)}
                                    fill={index <= (userrating[appointment._id] || 0) ? 'gold' : '#e1e1e1'}
                                    size={30}
                                    style={{ cursor: 'pointer' }}
                                  />
                                ))}  
                              </div>
                            )
                          )}
                        </div>
                      </div>
                        <div>
                          <p>
                          <strong>Date:</strong> {appointment.date.split('T')[0]}
                          </p>
                          <p>
                          <strong>Time:</strong> {appointment.time}
                          </p>
                          <p>
                          <strong>Service:</strong> {appointment.service}
                          </p>
                        </div>
                    </div>
                  ))}
                </div>
              ) : (
              <p>No Old appointments were Found.</p>
              )) :
              (
                <div>
                  <p>Loading...</p>
                </div>
              )}
              
              {pastappointments && pastappointments.length > visiblePastAppointments && (
                <div className='seemore_container'>
                  <button className='seemore' onClick={showMorePastAppointments}>Load More <div className='seemore_arrow'><ArrowIconRight/></div></button>
                  
                </div>
              )}


              
            </div>
          </div>
        </div>

        <div className='Servicesdata'>
            <h1>Services</h1>
              <div className="service_container">
                  <div className="service_grid">
                      {services.length > 0 ? (
                        services.map((service) => (
                            <div className="service_card" key={service._id}>
                            <h2 className="service-title">{service.Service}</h2>
                            <p className="service_price">Price: <span>{service.Price}</span></p>
                            <p className="service_description">Description: <span>{service.Description}</span></p>
                            <p className="service_status">Status: <span>{service.Status ? 'Active' : 'Inactive'}</span></p>
                            </div>
                        ))
                      ) : (
                      <div className="no-services"><p>No Services available</p></div>
                      )}
                  </div>
              </div>
        </div>

      </div>

    </div>
  )
}

export default Dashboard